import { generatePlayerIframeUrlForEmbedInstance } from '../utils/playerUrl';
import { Logger } from '../logging/Logger';
import Bowser from 'bowser';
import BambuserLiveShopping from '../embed';
import { getDefaultPlayerSettings, parsePlayerSettings } from './settings';
import { BAM_PLAYER_FIT, HORIZONTAL_POSITION, SCALE_MODE } from './constants';
import { getStyleSheetForBamPlayer } from './bamPlayerStyles';
import { resizedImage, DEFAULT_RESIZE_OPTIONS_PRODUCT_SMALL } from '../../../player/src/shared/utils/image';

const { setTimeout } = window;

const MAX_FONT_SIZE = 20;
const MIN_FONT_SIZE = 8;

// Below this width, we'll show only thumbnails (no title/subtitle) in the overlay
const MAX_WIDTH_TO_SHOW_ONLY_THUMBNAILS = 220;
const MAX_HEIGHT_TO_SHOW_ONLY_THUMBNAILS = 190;

const browser = Bowser.getParser(window.navigator.userAgent);
const isUnsupportedBrowser = browser.satisfies({
  windows: { 'internet explorer': '<=11' },
  mobile: { safari: '<11' },
});

const validateUrl = (url) => {
  try {
    return new URL(url).toString();
  } catch (err) {
    return null;
  }
};

/**
 * A web component that embeds a Bambuser Live Shopping player.
 *
 * Example:
 *  <bam-player event-id="1234" autoplay="hover"></bam-player>
 *
 * The web component wraps a Bambuser Live Shopping player in an iframe,
 * loads it in a light-weight manner and provides a simple API to control
 * the player, including events and methods for play, pause, mute, etc.
 *
 * By default, the web component will expand the player when clicked.
 * This behavior can be disabled by setting the `standalone` attribute to `false`.
 *
 * Certain visual aspects of the player can be controlled via CSS custom
 * properties (see _updateStylesheet()).
 *
 * When initially attached to the DOM, the web component will load the player
 * in an iframe but only the video, not the player UI. The player UI will be
 * loaded when the user interacts with the player, e.g. by clicking on it.
 */
export default class BambuserPlayerComponent extends window.HTMLElement {
  // Changes in these attributes will trigger attributeChangedCallback()
  static observedAttributes = [
    'event-id',
    'video-id',
    'autoplay',
    'loop',
    'poster',
    'settings',
    'show-products',
  ];

  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
    this._id = Math.random().toString(36).substring(2, 9);

    this._settings = getDefaultPlayerSettings();
    this._isProductViewShown = false;
    this._isProductListShown = false;
    this._isExpandedInPlaylist = false;
    this._poster = null;
    this._isMuted = undefined;
    this._theme = {};
  }

  set _expanded(value) {
    this._isExpandedInPlaylist = value;

    if (this._isExpandedInPlaylist) {
      this.wrapper.classList.add('is-expanded');
    } else {
      this.wrapper.classList.remove('is-expanded');
    }
  }

  /** Standard Web Component methods */

  connectedCallback() {
    if (isUnsupportedBrowser) {
      // Render nothing if the browser is unsupported
      return;
    }
    if (this._hasConnected) return; // TODO: figure out why connectedCallback is called multiple times
    this._hasConnected = true;

    this._updateSettingsFromAttributes();
    this._setupDOM();
    this._setup();
  }

  disconnectedCallback() {
    this._unloadResizeObserver?.();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this._hasConnected) {
      // Do nothing if the component hasn't been connected to the DOM yet
      return;
    }

    if (this._isPlayerUILoaded) {
      // Do nothing if the player is expanded
      return;
    }

    if (name === 'event-id' || name === 'video-id') {
      if (!newValue) {
        // Tear down the player if the source video attribute is removed
        this._tearDown();
        return;
      }
      if (newValue !== oldValue) {
        this._tearDown();
        this._setupDOM();
        this._setup();
      }
    }

    if (name === 'autoplay') {
      // The autoplay attribute was changed. We need to re-setup the DOM event listeners.
      this.pause();
      this._unloadDOMEventListeners?.();
      this._setupDOMEventListeners();
    }

    if (name === 'poster') {
      this._updateStylesheet();
    }

    if (name === 'settings') {
      this._updateSettingsFromAttributes();
      this._updateOverlayDOM();
      this._updateStylesheet();
    }
  }

  /** Custom methods */

  get _attr() {
    const eventId = this.getAttribute('event-id');
    const videoId = this.getAttribute('video-id');
    const autoplay = this.getAttribute('autoplay')?.toLocaleLowerCase() || this.hasAttribute('autoplay') || false;
    const loop = this.getAttribute('loop') || this.hasAttribute('loop') || false;
    const standalone = this.getAttribute('standalone') !== 'false';
    const poster = this.getAttribute('poster');
    const settings = this.getAttribute('settings');
    const showProducts = this.getAttribute('show-products') !== 'false';
    const distributionPageId = this.getAttribute('distribution-page-id');
    const distributionContainerId = this.getAttribute('distribution-container-id');
    const playerFit = this.getAttribute('player-fit') || BAM_PLAYER_FIT.SAME_HEIGHT;
    const landscapeInPortrait =
      this.getAttribute('landscape-in-portrait') || SCALE_MODE.FIT;
    const showPosterWhenPaused = this.getAttribute('show-poster-when-paused') === 'true';
    const startMuted = this.getAttribute('start-muted') === 'true';

    return {
      eventId,
      videoId,
      autoplay,
      loop,
      standalone,
      poster,
      settings,
      showProducts,
      distributionPageId,
      distributionContainerId,
      playerFit,
      landscapeInPortrait,
      showPosterWhenPaused,
      startMuted,
    };
  }

  _getPlayerUrl() {
    const {
      eventId,
      videoId,
      autoplay,
      standalone,
      distributionPageId,
      distributionContainerId,
      landscapeInPortrait,
      startMuted,
    } = this._attr;

    // Tell the player to autoplay if set to any value other than "hover" or "visible" which are
    // options that we'll handle ourselves in the web component
    const shouldPlayerAutoplay = autoplay === true || (autoplay && autoplay !== 'hover' && autoplay !== 'visible');

    // Combine the config (optionally) provided by the parent page with our own necessary options
    const config = {
      ...this.embedInstance.getProviderConfig(), // config provided via player.configure() in onBambuserLiveShoppingReady()
      ...this.embedInstance.config,
      eventId, // Either eventId or videoId is required, but not both
      videoId,
      autoplay: shouldPlayerAutoplay,
      type: 'overlay',
      playerVersion: 2,
      withBaaSPlayerPreloading: true, // BaaS player should be preloaded outside of React app
      deferReactAppInit: true, // we will control when the React app (the player UI) should be loaded
      withMinimizeSupport: false, // miniplayer is not yet compatible with this web component
      ...(!standalone && {
        // when we aren't handling everything inside bam-player, the controller is expected to add a close button
        hideCloseButton: true,
      }),
      ...(videoId && {
        hideShareButton: true,
        hideEmojiOverlay: true,
      }),
      ...(window.location.host === 'localhost:5080' && { playerUrl: 'http://local.bambuser.com/' }),
      ...(distributionPageId && { distributionPageId }),
      ...(distributionContainerId && { distributionContainerId }),
      ...(landscapeInPortrait && { landscapeInPortrait } ),
      ...(startMuted && { startMuted }),
    };

    const url = generatePlayerIframeUrlForEmbedInstance({
      config,
      logger: new Logger(),
    });

    // Append some query params of our own that aren't known by generatePlayerIframeUrlForEmbedInstance()
    const additionalQueryParams = new URLSearchParams({
      widgetId: this._id,
      ...(!standalone && {
        // show all products in the player timeline when a player's appearance is controlled by a parent (e.g. bamBundle)
        autoShowAllProductsOnHighlightedArea: true,
      }),
    });

    return `${url}&${additionalQueryParams.toString()}`;
  }

  _updateSettingsFromAttributes() {
    const { settings: settingsAttributeValue } = this._attr;
    if (!settingsAttributeValue) return;

    this._settings = { ...this._settings, ...parsePlayerSettings(settingsAttributeValue)};
  };

  _updateStylesheet() {
    let width = this._videoWidth;
    let height = this._videoHeight;
    if (!isFinite(width) || !isFinite(height) || width <= 0 || height <= 0) {
      width = null;
      height = null;
    }
    const aspectRatio = width && height ? width / height : undefined;

    // Calculate an appropriate font size based on the height of the container
    const { height: containerHeight, width: containerWidth } = this.getBoundingClientRect();
    const fontSize = Math.max(MIN_FONT_SIZE, Math.min(Math.round(containerHeight / 35), MAX_FONT_SIZE));

    const showOnlyThumbnails = this._settings.productCardMode === 'default' &&
      (containerWidth < MAX_WIDTH_TO_SHOW_ONLY_THUMBNAILS || containerHeight < MAX_HEIGHT_TO_SHOW_ONLY_THUMBNAILS);

    // Use poster image if provided, either via the "poster" attribute or via the "pending curtain" on the show
    const { playerFit, poster: customPoster, showPosterWhenPaused } = this._attr;
    const { backgroundImage: pendingCurtainImage } = this?._event?.customPendingStyle || {};
    const posterRawUrl = validateUrl(customPoster) || validateUrl(this._poster) || validateUrl(pendingCurtainImage);
    const poster = posterRawUrl ? resizedImage(posterRawUrl) : null;

    // Load theme details
    const theme = this._theme;
    const playerActionCardBorderRadius = theme?.generalV2?.playerSettings?.misc?.borderRadiusBase;
    this.styleSheet.textContent = getStyleSheetForBamPlayer({
      aspectRatio,
      fontSize,
      showOnlyThumbnails,
      poster,
      playerFit,
      showPosterWhenPaused,
      playerActionCardBorderRadius,
    });
  }

  _getBasicInfoAsText() {
    const { title } = this._event || {};

    if (!title) return '';

    const { title: shouldDisplayTitle, overlayTextWrap: shouldWrapText } = this._settings;
    if (!shouldDisplayTitle) return '';

    return `
      <div class="basic-info ${shouldWrapText ? 'wrap-text' : ''}">
        <h1 class="title">${title}</h1>
      </div>
    `;
  }

  _getProductInfo() {
    const { _products, _settings } = this;
    const { showProducts } = this._attr;

    if (!showProducts || !_settings.products || !_products || !_products.length) return '';

    const { title, brand, thumbnail, price, currency } = _products[0];

    // Use imageTransformer to resize and cache the thumbnail
    const resizedThumbnailURL = thumbnail  && resizedImage(thumbnail, {
      ...DEFAULT_RESIZE_OPTIONS_PRODUCT_SMALL,

      // Since it's difficult to determine an organization's settings from the web component
      // we'll use the static IP proxy unconditionally. The downside is that once inside the
      // full player, the image be loaded via imageTransformer but without the static IP proxy.
      staticIP: 1,
    });

    const thumbnailHtml = `<div class="product-thumbnail-wrapper">
        <img src="${resizedThumbnailURL}" alt="${title}" />
      </div>`;
    let productInfoHtml = `<div class="product-info">
        <div class="product-title">${title}</div>
        ${brand ? `<div class="product-subtitle">${brand}</div>` : ''}
        ${price ? `<div class="product-price">${price} ${currency}</div>` : ''}
      </div>`;

    switch (_settings.productCardMode) {
      case 'thumbnail':
        productInfoHtml = '';
        break;
      case 'full':
      case 'default':
      default:
        // Do nothing
        // In 'full' we always show both thumbnail and product info
        // In 'default' we show both if there's enough space, just thumbnail otherwise
        break;
    }

    return `
      <div class="products">
          <div class="product">
            ${thumbnailHtml}
            ${productInfoHtml}
          </div>
      </div>
    `;
  }

  _updateOverlayDOM() {
    const { overlay } = this;

    // Current business rule is to show product or if no product, show basic info (title)
    const productView = this._getProductInfo();
    const basicInfo = this._getBasicInfoAsText();

    if (productView) {
      overlay.innerHTML = `${productView}`;
    } else {
      overlay.innerHTML = `${basicInfo}`;
    }
  }

  _setupDOM() {
    // Make sure we start from a clean slate
    this.shadow.innerHTML = '';

    const styleSheet = document.createElement('style');
    this.styleSheet = styleSheet;

    const placeholder = document.createElement('div');
    placeholder.classList.add('placeholder');

    const overlay = document.createElement('div');
    overlay.classList.add('overlay');
    this.overlay = overlay;

    const wrapper = document.createElement('div');
    wrapper.classList.add('wrapper');
    this.wrapper = wrapper;

    // TODO: support these iframe attributes:
    // sandbox
    // credentialless
    const iframe = document.createElement('iframe');
    iframe.setAttribute('allow', 'fullscreen; autoplay; web-share');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowTransparency', '1');
    iframe.setAttribute('title', 'Live video shopping');
    this.iframe = iframe;

    wrapper.appendChild(overlay);
    wrapper.appendChild(iframe);
    this.shadow.appendChild(styleSheet);
    this.shadow.appendChild(placeholder);
    this.shadow.appendChild(wrapper);

    // Bump the CSS when the component changes in size. This is primarily to update the font size.
    if ('ResizeObserver' in window) {
      const resizeObserver = new window.ResizeObserver(() => {
        setTimeout(() => {
          if (this._isPlayerUILoaded) return;
          this._updateStylesheet();
        }, 0);
      });
      resizeObserver.observe(wrapper);
      this._unloadResizeObserver = () => resizeObserver.disconnect();
    }
  }

  _setupDOMEventListeners() {
    if (this._hasDOMEventListeners) return;
    this._hasDOMEventListeners = true;

    const { autoplay, standalone } = this._attr;

    const onUnloadCallbacks = [];
    onUnloadCallbacks.push(() => this._hasDOMEventListeners = false);

    if (standalone) {
      // The player is set to be standalone, meaning it itself is responsible for expanding
      // the player to full screen when clicked.
      const onClick = () => this._onClick();
      this.wrapper.addEventListener('click', onClick);
      onUnloadCallbacks.push(() => this.wrapper.removeEventListener('click', onClick));
    }

    // When the autoplay property is set to "hover" (as opposed to boolean)
    // we'll play/pause the video when the user hovers over the iframe
    if (autoplay === 'hover') {
      const onHoverEvent = (event) => {
        if (this._isExpandedInPlaylist) return;

        if (event.type === 'mouseenter') {
          this.play();
        } else {
          this.pause();
        }
      };
      this.wrapper.addEventListener('mouseenter', onHoverEvent);
      this.wrapper.addEventListener('mouseleave', onHoverEvent);
      onUnloadCallbacks.push(() => {
        this.wrapper.removeEventListener('mouseenter', onHoverEvent);
        this.wrapper.removeEventListener('mouseleave', onHoverEvent);
      });
    }

    this._shouldDisconnectObserverWhenVisible = true;
    const observer = new window.IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this._emit('visible');
        if (this._shouldDisconnectObserverWhenVisible) {
          observer.disconnect();
        }
        this._hasBeenVisible = true; // Used to track if the player has been visible at some point
      } else {
        this._emit('hidden');
      }
    });
    observer.observe(this.wrapper);
    onUnloadCallbacks.push(() => observer.disconnect());

    // When the autoplay property is set to "visible" we'll play/pause the video
    // when the iframe is visible in the viewport
    if (autoplay === 'visible') {
      this._shouldDisconnectObserverWhenVisible = false;
      if (this._isVisibleOnPage()) {
        this.play();
      }

      // When the autoplay property is set to "visible" (as opposed to boolean)
      // we'll play/pause the video when the iframe is visible in the viewport
      const onVisible = () => this.play();
      const onHidden = () => this.pause();
      this.addEventListener('visible', onVisible);
      this.addEventListener('hidden', onHidden);
      onUnloadCallbacks.push(() => {
        this._shouldDisconnectObserverWhenVisible = true;
        this.removeEventListener('visible', onVisible);
        this.removeEventListener('hidden', onHidden);
      });
    }

    this._unloadDOMEventListeners = () => {
      onUnloadCallbacks.forEach((handler) => handler());
    };
  }

  _isVisibleOnPage() {
    if (!this.wrapper) return false;
    if ('checkVisibility' in this.wrapper) {
      if (!this.wrapper.checkVisibility()) {
        // The element is not visible even though it may be in the viewport
        return false;
      }
    }
    const { top, bottom } = this.wrapper.getBoundingClientRect();
    return top < window.innerHeight && bottom > 0;
  }

  setMediaAssets(mediaAssets) {
    if (!isNaN(mediaAssets[0]?.data?.width) && !isNaN(mediaAssets[0]?.data?.height)) {
      this._videoWidth = mediaAssets[0]?.data?.width;
      this._videoHeight = mediaAssets[0]?.data?.height;
    }

    if(mediaAssets[0]?.data?.preview) {
      this._poster = mediaAssets[0]?.data?.preview;
    }
  }

  getVisibleArea() {
    if (!this.wrapper) return 0;
    if (!this._isVisibleOnPage()) return 0;

    const { top, bottom, left, right, width, height } = this.wrapper.getBoundingClientRect();

    const visibleHeight = Math.min(window.innerHeight, bottom) - Math.max(0, top);
    const visibleWidth = Math.min(window.innerWidth, right) - Math.max(0, left);

    const visiblePlayerArea = visibleWidth * visibleHeight;
    const totalPlayerArea = width * height;

    const visibleArea = Math.ceil(100 * (visiblePlayerArea / totalPlayerArea)) / 100;

    return visibleArea;
  }

  positionRelativeToCenter() {
    if (!this.wrapper) return false;
    if (!this._isVisibleOnPage()) return false;

    const CENTERING_THRESHOLD = 15;
    const { left, width } = this.wrapper.getBoundingClientRect();

    const windowCenter = window.innerWidth / 2;
    const playerCenter = left + (width / 2);

    // if central line of the player is farther than central line of the window
    // by more than CENTERING_THRESHOLD pixels - then it's not centered
    const isCenteredHorizontally = Math.abs(playerCenter - windowCenter) < CENTERING_THRESHOLD;

    return isCenteredHorizontally
      ? HORIZONTAL_POSITION.CENTER
      : (playerCenter <= windowCenter ? HORIZONTAL_POSITION.LEFT : HORIZONTAL_POSITION.RIGHT);
  }

  _setupPlayerPageEventListeners() {
    const playerUrl = this._getPlayerUrl();
    const { origin: iframeOrigin } = new URL(playerUrl);

    const onPlayerIframeMessage = ({ data: rawData, origin }) => {
      if (origin !== iframeOrigin) return;
      const { eventName, data, widgetId, isVod } = rawData || {};

      // Make sure we're not handling a message intended for another instance of this web component
      if (widgetId !== this._id) return;

      if (eventName === 'baas-player:eventData') {
        this._event = data;
        this.embedInstance.updateTrackingContextData({
          orgId: this._event.orgId,
        });
        this._updateOverlayDOM();
        this._updateStylesheet();

        // Log the initialization of the web component including the attributes
        // Defer until the player has been visible in the viewport at least once
        const trackOnVisible = () => {
          this._trackMetric('on-initialized', { ...this._attr });
          this.removeEventListener('visible', trackOnVisible);
        };
        if (this._hasBeenVisible) {
          trackOnVisible();
        } else {
          this.addEventListener('visible', trackOnVisible);
        }
      }

      if (eventName === 'baas-player:theme') {
        this._theme = data;
      }

      if (eventName === 'baas-player:broadcastData') {
        this._poster = data?.preview;
        this._videoWidth = data?.width;
        this._videoHeight = data?.height;
        this._broadcastId = data?.id;

        this._isReady = true;
        this.setScaleMode('aspectFill');
        this.setObjectPosition('center');

        // Adjust the aspect ratio of the iframe to match the broadcast
        // and set the width of the wrapper to match the broadcast width
        this._updateStylesheet();

        // If autoplay=visible and the element happens to visible now that the
        // player is ready we should start playback
        if (this._attr.autoplay === 'visible' && this._isVisibleOnPage()) {
          this.play();
        }
      }

      if (eventName === 'baas-player:products') {
        this._products = data;
        this._updateOverlayDOM();
      }

      if (eventName === 'baas-player:playing') {
        const wasPlaying = this._isPlaying;
        this._isPlaying = true;

        if (!wasPlaying && isVod) {
          // If currentTime is 0 or NaN, the video is starting from the beginning,
          // otherwise it's a resuming playback
          const metric = isNaN(this._currentTime) || this._currentTime === 0
            ? 'on-play'
            : 'on-resume';
          this._trackMetric(metric);
          // Only emit 3 second progress if the video is starting from the beginning
          // If the player is expanded before the 3 second mark, we'll emit the 3 second
          // in the player UI instead
          if (metric === 'on-play') {
            this._emitOnProgress(3, false);
          }
          this._emitOnProgress(30, true);
        }
        this.wrapper.classList.add('is-playing');
        this.wrapper.classList.add('played-at-least-once');

        this._emit('playing');
      }

      if (eventName === 'baas-player:pause') {
        const wasPlaying = this._isPlaying;
        this._isPlaying = false;
        if (!this._isPlayerUILoaded) {
          this.wrapper.classList.remove('is-playing');
        }
        if (wasPlaying && isVod && !this._isExpandedInPlaylist) {
          this._trackMetric('on-pause');
        }
        this._emit('pause');
        this._clearProgressEmissions();
      }

      if (eventName === 'baas-player:ended') {
        this._isPlaying = false;
        if (isVod) {
          this._trackMetric('on-stop');
        }

        // If the player UI is loaded, we'll handle the ended via playback-status event
        if (!this._isPlayerUILoaded) this._onEnded();

        this._clearProgressEmissions();
      }

      if (eventName === 'baas-player:timeupdate') {
        this._currentTime = data?.currentTime || 0;
      }
    };

    window.addEventListener('message', onPlayerIframeMessage);
    this._unloadPlayerEventListeners = () => {
      window.removeEventListener('message', onPlayerIframeMessage);
    };
  }

  _setup() {
    this._isReady = false;
    this._event = null;
    this._products = null;
    this._isPlayerUILoaded = false;
    this._currentTime = this._attr.videoId ? 0 : undefined;

    if (!this._didDefer && document.readyState === 'loading') {
      // We should defer loading the player until the DOM is ready
      this._didDefer = true;
      document.addEventListener('DOMContentLoaded', () => this._setup());
      return;
    }

    if (!this._didDefer2 && 'requestIdleCallback' in window) {
      // Defer loading the player until the browser is idle
      this._didDefer2 = true;
      window.requestIdleCallback(() => {
        this._setup();
      }, {
        timeout: 5000, // Don't wait more than 5 seconds
      });
      return;
    }

    // Remove any previous event listeners
    this._unloadDOMEventListeners?.();
    this._unloadPlayerEventListeners?.();

    if (!this._attr.eventId && !this._attr.videoId) return;
    if (this._attr.eventId && this._attr.videoId) {
      throw new Error('Both eventId and videoId cannot be set at the same time');
    }

    // Setup the instance of BambuserLiveShopping (embed.js) that will handle the full player once it's opened
    this._createEmbedInstance();

    // Setup event listeners for the DOM
    this._setupDOMEventListeners();

    // Listen to events sent to us from the player page
    this._setupPlayerPageEventListeners();

    this._updateSettingsFromAttributes();

    this._updateStylesheet();

    // Load the player in the iframe
    this.iframe.src = this._getPlayerUrl();
  }

  _createEmbedInstance() {
    const { eventId: eid, videoId } = this._attr;
    const eventId = eid || videoId; // Map videoId to eventId to piggypack on existing logic
    if (!eventId) return;

    if (this.embedInstance) {
      this.embedInstance.destroy();
    }

    const embedInstance = new BambuserLiveShopping({
      isVod: Boolean(!eid && videoId),
      eventId,
      type: 'overlay',
      containerNode: this.wrapper, // Use our DOM element instead of letting embed instance create one
      // Hide the products if the attribute is set to false, in the dashboard we refer to this as "showProducts" but
      // when the attribute is set to false we should hide the products.
      hideProducts: this._attr?.showProducts === false,
    });
    this.embedInstance = embedInstance;

    // Calling mountPlayerAPI will make the embed instance call onBambuserLiveShoppingReady()
    this.embedInstance.mountPlayerAPI(eventId);

    // Apply the provider config (player.configure({ this guy })) on the embed instance internal config object
    // This puts the userId/sessionId in the right places for them to be included
    // later when we call trackMetric()
    this.embedInstance.applyProviderConfig();

    // Reset to the initial state when the user closes the player
    embedInstance.playerDelegate.on('close', () => {
      this._onClose();
      this._emit('close');
    });

    embedInstance.playerDelegate.on('playback-status', (data) => {
      if (data?.ended && !data?.seeking) {
        this._isPlaying = false;
        this._onEnded();
      }
    });

    embedInstance.playerDelegate.on('should-show-product-list', () => this._onProductListShown());
    embedInstance.playerDelegate.on('should-hide-product-list', () => this._onProductListHidden());
    embedInstance.playerDelegate.on('should-show-product-view', () => this._onProductViewShown());
    embedInstance.playerDelegate.on('should-hide-product-view', () => this._onProductViewHidden());

    // Redispatch swipe events
    embedInstance.playerDelegate.on('player-swipe-right', () => this._emit('swipe-right'));
    embedInstance.playerDelegate.on('player-swipe-left', () => this._emit('swipe-left'));
    embedInstance.playerDelegate.on('player-swipe-up', () => this._emit('swipe-up'));
    embedInstance.playerDelegate.on('player-swipe-down', () => this._emit('swipe-down'));

    embedInstance.playerDelegate.on('muted', () => this._onMutedFromPlayerUI());
    embedInstance.playerDelegate.on('unmuted', () => this._onUnmutedFromPlayerUI());
  }

  _onMutedFromPlayerUI() {
    this._emit('muted');
  }

  _onUnmutedFromPlayerUI() {
    this._emit('unmuted');
  }

  _onProductViewShown() {
    this._isProductViewShown = true;
    this._emit('show-product');
  }

  _onProductViewHidden() {
    this._isProductViewShown = false;
    this._emit('hide-product');
  }

  _onProductListShown() {
    this._isProductListShown = true;
  }

  _onProductListHidden() {
    this._isProductListShown = false;
  }

  hasImportantUIShown() {
    return this._isProductViewShown || this._isProductListShown;
  }

  _onClick() {
    if (!this._isReady) return;
    this._loadPlayerUI();
    this.wrapper.classList.add('is-expanded');
  }

  _onClose() {
    this._unloadPlayerUI();
    this.wrapper.classList.remove('is-expanded');

    // Remove some styling that were set by the embed instance
    this.wrapper.style = '';
  }

  _onEnded() {
    if (this._attr.loop && !this._isPlayerUILoaded) {
      // Restart playback after it ends
      // NOTE: Without the delay the player will get stuck in some weird loop and
      // play the last second of the video over and over again.
      setTimeout(() => {
        this.setCurrentTime(0);
        this.play();
      }, 500);
    }

    this._emit('ended');
  }

  _tearDown() {
    this.shadow.innerHTML = '';

    this._isReady = false;
    this._event = null;
    this._products = null;
    this._isPlayerUILoaded = false;
    this._currentTime = undefined;

    this._unloadDOMEventListeners?.();
    this._unloadPlayerEventListeners?.();
    this._updateOverlayDOM();

    this.embedInstance?.destroy();
    delete this.embedInstance;
  }

  _loadPlayerUI() {
    if (this._isPlayerUILoaded) return;
    this._isPlayerUILoaded = true;

    // Stop listening to events on the web component itself. Let the player UI handle it from now on.
    this._unloadDOMEventListeners?.();

    // Beware! The methods called on this.embedInstance must be called in the correct order.

    if (this._attr.standalone) {
      this.embedInstance.show(); // show() calls loadPlayer() internally
    } else {
      this.embedInstance.loadPlayer();
    }

    // Tell player to load the React app (= the UI)
    this.iframe.contentWindow.postMessage('initReactApp', '*');

    if (this._isMuted === false || (this._isMuted === undefined && this._attr.startMuted === false)) {
      // Since the user has interacted with the video, we should be allowed to unmute now
      this.unmute();
    }

    this.play();

    this.wrapper.classList.add('player-ui-loaded');
    this._isPlayerUILoaded = true;

    if (!this._hasTrackedPlayerUILoaded) {
      this._hasTrackedPlayerUILoaded = true;
      this._trackMetric('on-interaction', { interactionType: 'load-player-ui' });
    }
  }

  _unloadPlayerUI() {
    this.mute();

    this._isProductViewShown = false;
    this._isProductListShown = false;

    // Tell player to unload the React app (= the UI).
    // NOTE: The player-side implementation is, at the time of writing, a bit flaky.
    // We might need to do a full page reload in some cases.
    this.iframe.contentWindow.postMessage('unloadReactApp', '*');

    // Recreate the embed instance to make sure we start from a clean slate next time
    this._createEmbedInstance();

    // Start listening to events on the web component again
    this._setupDOMEventListeners();

    this.wrapper.classList.remove('player-ui-loaded');
    this._isPlayerUILoaded = false;
    this.wrapper.style = '';
  }

  _emit(eventName) {
    const event = new window.CustomEvent(eventName);
    this.dispatchEvent(event);
  }

  _getTimelineForTracking() {
    // timeline field is not part of tracking schema
    // but it's useful to have it in the tracking data
    // and it's already added in the player's tracking data
    return {
      broadcastId: this._broadcastId,
    };
  }

  _trackMetric(metric, data) {
    // Never emit metrics if the player UI is loaded since it has it's
    // own metrics tracking service
    if (this._isPlayerUILoaded) return;
    const sourceAppPrefix = this._attr.videoId ? 'vod-' : '';
    this.embedInstance._trackMetric(metric, {
      orgId: this._event?.orgId,
      playerVersion: 2,
      source: 'player',
      sourceApp: `${sourceAppPrefix}player-web-components`,
      isPlaying: this._isPlaying ?? false,
      schemaVersion: '2.0.0',
      clientTimestamp: new Date().toISOString(),
      videoCurrentTime: isNaN(this._currentTime) ? 0 : Math.floor(this._currentTime),
      distributionPageId: this._attr.distributionPageId,
      distributionContainerId: this._attr.distributionContainerId,
      timeline: this._getTimelineForTracking(),
      ...(this._event && { isLive: this._event.isLive }),
      ...data,
    });
  }

  _emitOnProgress(delay, repeat) {
    this._emitProgressTimeouts ??= {};
    const timeoutKey = `${delay}:${repeat}`;

    window.clearTimeout(this._emitProgressTimeouts[timeoutKey]);

    const cb = () => {
      this._trackMetric('on-progress');
      this._emitProgressTimeouts[timeoutKey] = repeat
        ? setTimeout(cb, delay * 1000)
        : null;
    };
    // Store the timeout with it's specific key so we don't clear
    // the wrong timeout if the delay is changed
    this._emitProgressTimeouts[timeoutKey] = setTimeout(cb, delay * 1000);
  }
  
  _clearProgressEmissions() {
    this._emitProgressTimeouts && Object.values(this._emitProgressTimeouts)?.forEach(i => window.clearTimeout(i));
  }

  /** Player control functions below */

  play() {
    this._sendPlayerControlMessage('play');
  }

  pause() {
    this._sendPlayerControlMessage('pause');
  }

  unmute() {
    this._sendPlayerControlMessage('unmute');
  }

  mute() {
    this._sendPlayerControlMessage('mute');
  }

  setCurrentTime(time) {
    if (typeof time !== 'number' || time < 0) throw new Error('setCurrentTime() expects a number');
    this._sendPlayerControlMessage('setCurrentTime', { time });
  }

  setScaleMode(scaleMode) {
    // scaleMode: 'aspectFit' | 'aspectFill' | 'fill'
    this._sendPlayerControlMessage('setScaleMode', scaleMode);
  }

  updateScaleMode() {
    if (this._videoWidth > this._videoHeight) {
      // If the video is landscape and the window is portrait, we should fit the video
      if (this._attr.landscapeInPortrait === SCALE_MODE.FILL) {
        this.setScaleMode('aspectFill');
      } else {
        this.setScaleMode('aspectFit');
      }
    } else {
      this.setScaleMode('aspectFill');
    }
  }

  setObjectPosition(objectPosition) {
    this._sendPlayerControlMessage('setObjectPosition', objectPosition);
  }

  _saveGlobalState({ muted }) {
    this._isMuted = muted;
  }

  _sendPlayerControlMessage(action, data) {
    if (!this.iframe?.contentWindow) return;
    this.iframe.contentWindow.postMessage({
      eventName: `widgets:${action}`,
      data,
    }, '*');
  }
}
