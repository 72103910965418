import {
  PLAYER_EVENTS,
  PLAYER_EVENTS_PUBLIC,
  PLAYER_EVENT_PREFIX,
  PLAYER_EVENTS_TO_FORWARD,
  PLAYER_CART_EVENTS,
  TRACKING_COOKIES,
  SURF_FRAME_EVENTS,
  FLOATING_PLAYER_NAVIGATION_MODES,
  ORIENTATION,
  VIDEO_ORIENTATION,
  MINIMIZED_POSITION,
  MINIPLAYER_SIZE,
  SURF_FRAME_EVENT_PREFIX,
} from './constants';
import { BambuserLiveShoppingError } from './errors/BambuserLiveShoppingError';
import Bowser from 'bowser';
import {
  isUndesiredBrowser,
  tryOpenInExternalBrowser,
  isAppleMobileDevice,
  isMacWithMultiTouch,
} from '../../player/src/shared/utils/browser';
import { isFullscreen, enterFullscreen, exitFullscreen, listenToFullscreenEvents } from './utils/fullscreen.js';
import { readCookie, writeCookie } from './utils/cookies';
import { v4 as uuidv4 } from 'uuid';
import CreateProduct from './utils/productSpecGenerator';
import { SchemaModel } from './utils/schemaModel/SchemaModel';
import {
  BambuserLSLegacyConfigurationTransformer,
  BambuserLiveShoppingPlayerAPIDelegate,
  BambuserLiveShoppingPlayerAPIEmbedInstanceAdapter,
} from './playerApi';
import { Logger } from './logging/Logger';
import surfWithSrcdocFrame from './utils/surfWithSrcdocFrame';
import { EDGE, MIN_WIDTH, MAX_WIDTH_FRACTION, SNAP, QUICK_MOVE } from './config';
import { isActionKey, isBackNav, isForwardNav } from './utils/keyboard';
import Navigation from './utils/navigation';
import WARNING from './constant/warning';
import { getNodeTopParent, isValidEventIdFormat, isValidVideoIdFormat } from './utils/helpers';
import { initGA, initGAConfig, bamlsTag, bamlsTagData } from './utils/analytics';
import { captureMessage } from './utils/sentry';
import { generatePlayerIframeUrlForEmbedInstance } from './utils/playerUrl';
import {
  defaultCloseIcon,
  defaultRestoreIcon,
  getIOSActivePlayerStyles,
  getStyles,
  getStylesInline,
  defaultCloseIconV2,
} from './styles';
import { ELCBambuserLiveShoppingConfiguration } from './playerApi/brand-specific-configuration/ELCBambuserLiveShoppingConfiguration';

const isDev = process.env.NODE_ENV === 'development';

const DEFAULT_CUSTOMIZATION = 'default';
const MINIMIZE_TRANSITION_TIME = 300;
const urlParams = new URLSearchParams(window.location.search);
let instanceCounter = 1;

const { MANUAL, IFRAME_SRCDOC: SRCDOC } = FLOATING_PLAYER_NAVIGATION_MODES;
const { NORTH, SOUTH, EAST, WEST, NORTH_EAST, SOUTH_EAST, SOUTH_WEST, NORTH_WEST } = ORIENTATION;

// It's important to note that this will not reference the <script> element 
// if the code in the script is being called as a callback or event handler; 
// it will only reference the element while it's initially being processed.
// Do not put this instruction into the constructor, it will not work.
// https://developer.mozilla.org/en-US/docs/Web/API/Document/currentScript
const currentScriptSrc = document.currentScript && document.currentScript.src;

const elcBrands = [
  'ZXN0ZWUtbGF1ZGVy', // estee-lauder
  'ZXN0ZWUtbGF1ZGVyLWdyZWVjZQ==', // estee-lauder-greece
  'bWFj', // mac
];

class BambuserLiveShopping {
  constructor(config) {
    this.customization = this._getCustomization(currentScriptSrc);
    const browser = Bowser.getParser(window.navigator.userAgent);
    // Warning: Detecting desktop browser currently skips any custom platform.
    this.isDesktop = browser.getPlatformType(true) === 'desktop';
    this.isNonSupportedBrowser = browser.satisfies({
      windows: { 'internet explorer': '<=11' },
      mobile: { safari: '<11' },
    });

    this.debug = (isDev && urlParams.get('debug')) || urlParams.get('debug-prod');
    this.logger = new Logger({
      logPrefix: `[EMBED${window.top === window ? '-TOP' : ''}] `,
      debug: this.debug,
      isDev,
    });

    this.setConfig(config, browser);

    this.playerEventsToForward = PLAYER_EVENTS_TO_FORWARD;
    this.instanceId = instanceCounter++;
    this.landingPagePageTitle = document.title;

    // surf behind
    this.surf = {
      frame: null,
      container: null,
      containerId: null,
      skipNextBrowserHistoryEntry: false,
    };
    this.player = {
      frame: null,
      container: null,
      containerId: null,
    };
    this.timeline = {
      isLive: false,
      currentTime: 0,
      currentBroadcastId: null,
      serverTimeAtCurrentPosition: 0,
    };
    this.playerSettings = {
      preferNewTabCheckout: true,
    };
    this.video = {
      videoOrientation: VIDEO_ORIENTATION.VERTICAL,
    };

    let snapHorizontalThresholdRatio, snapVerticalThresholdRatio, minWidth, thresholdX, thresholdY;

    if (this.isDesktop) {
      snapHorizontalThresholdRatio = SNAP.HORIZONTAL_THRESHOLD_ON_DESKTOP;
      snapVerticalThresholdRatio = SNAP.VERTICAL_THRESHOLD_ON_DESKTOP;
      minWidth = MIN_WIDTH.SMALL.DESKTOP;
      thresholdX = QUICK_MOVE.THRESHOLD_X_DESKTOP;
      thresholdY = QUICK_MOVE.THRESHOLD_Y_DESKTOP;
    } else {
      snapHorizontalThresholdRatio = SNAP.HORIZONTAL_THRESHOLD_ON_MOBILE;
      snapVerticalThresholdRatio = SNAP.VERTICAL_THRESHOLD_ON_MOBILE;
      minWidth = MIN_WIDTH.SMALL.MOBILE;
      thresholdX = QUICK_MOVE.THRESHOLD_X_MOBILE;
      thresholdY = QUICK_MOVE.THRESHOLD_Y_MOBILE;
    }

    this.miniPlayer = {
      position: { x: 0, y: 0 },
      requestedPosition: { x: 0, y: 0 },
      snapHorizontalThresholdRatio,
      snapVerticalThresholdRatio,
      snapThresholdOff: SNAP.THRESHOLD_OFF,
      minWidth,
      maxX: 0,
      maxY: 0,
    };

    this.quickMove = {
      enabled: QUICK_MOVE.ENABLED,
      orientation: '',
      targetedPosition: {},
      thresholdX,
      thresholdY,
      trustIndex: 0,
      timeout: null,
      detectionCount: 0,
    };

    this.onMiniPlayerMouseEnterLeave = this.onMiniPlayerMouseEnterLeave.bind(this);
    this.onFirstElementKeyDown = this.onFirstElementKeyDown.bind(this);
    this.onLastElementKeyDown = this.onLastElementKeyDown.bind(this);

    const playerAPIEmbedInstanceAdapter = new BambuserLiveShoppingPlayerAPIEmbedInstanceAdapter(this);
    let brandSpecificConfiguration;

    // Do a couple of overrides for ELC brands for whom we used to
    // provide custom embed.js files but don't anymore
    if (elcBrands.includes(window.btoa(this.customization))) {
      brandSpecificConfiguration = ELCBambuserLiveShoppingConfiguration;
    }

    this.playerDelegate = new BambuserLiveShoppingPlayerAPIDelegate(playerAPIEmbedInstanceAdapter, {
      configurationClass: brandSpecificConfiguration,
    });
    if (window.location.search.match(/(\?|&)bambuserAppDevEnv=1(&.*)?$/)) this._isAppDevEnv = true;
    this.addListeners();

    this._pendingRequestsToPlayer = {};
  }

  /**
   * In cases when the default embed script is used, users can insist on
   * a specific player customization by providing it's name in a
   * query parameter on the embed script URL
   * Or if the default script is served to us as a redirect we'll
   * pick the customization name from the original URL
   * @param {string} scriptSrc path to loaded embed script
   * @returns {string} customization name or DEFAULT_CUSTOMIZATION
   */
  _getCustomization(scriptSrc) {
    if (scriptSrc) {
      const { pathname, search } = new URL(scriptSrc);
      const searchParams = new URLSearchParams(search);
      return (
        searchParams.get('customization') || // pick "foo" from ?customization=foo
        (pathname.match(/\/([^\/]+)\/embed\.js/) || [])[1] || // pick "foo" from https://lcx-embed.bambuser.com/foo/embed.js
        DEFAULT_CUSTOMIZATION
      );
    }
    return DEFAULT_CUSTOMIZATION;
  }

  setConfig(config, browser) {
    this.config = config || {};
    // remove the hash so we don't add query parameters after it
    this.config.playerUrl = (this.config.playerUrl || process.env.WEBPLAYER_BASE_URL || '').split('#')[0];
    if (this.customization !== DEFAULT_CUSTOMIZATION)
      this.config.playerUrl = this.config.playerUrl.replace(/default/, `${this.customization}`);

    // Let developers insist on a specific player base URL by
    // setting a value in localStorage
    try {
      const playerUrlOverride = window.localStorage.getItem('bambuserPlayerBaseURLOverride');
      if (playerUrlOverride.match(/^https?:\/\//)) {
        this.config.playerUrl = playerUrlOverride;
      }
    } catch (err) {
      // This is fine
    }

    this.config.mockLive = urlParams.get('mockLiveBambuser');
    this.config.timestamp = urlParams.get('time');
    this.config.broadcastHash = urlParams.get('br');
    // Disabling fullscreen globally for non-desktop devices
    // iOS fullscreen is non standard and doesn't work on non-video elements
    // https://developer.apple.com/forums/thread/133248
    const allowFullscreen = this.isDesktop && browser.getOSName() !== 'iOS';
    this.config.allowFullscreen = allowFullscreen ? urlParams.get('allowFullscreen') : '0';
    this.config.playerVersion = this.playerVersion;
    this.config.isDashboard = this.isDashboard;
    this.config.allowShareAutoplay = this.isAutoplayAllowed();
    if (this.config.withGA) initGA(this.config.withGA);
  }

  addListeners() {
    window.addEventListener('bambuser-config', this.onConfigUpdate.bind(this), false);
  }

  onConfigUpdate({ detail: { edgeSpacing } = {} }) {
    captureMessage('Embed was configured via window event');
    if (!edgeSpacing) return;
    edgeSpacing = edgeSpacing[this.deviceType];
    if (!edgeSpacing) return;
    this.config.edgeSpacing = { ...this.config.edgeSpacing, ...edgeSpacing };
    this.setMiniPlayerInitialPosition();
    typeof this.__calculateMinimizedView === 'function' && this.__calculateMinimizedView();
    this.snapToBorders();
  }

  _noForwardCartEvents() {
    // eslint-disable-next-line
    for (const eventName of PLAYER_CART_EVENTS) {
      const index = this.playerEventsToForward.indexOf(eventName);
      this.playerEventsToForward.splice(index, 1);
    }
  }

  async show() {
    this.logger.log('show()');
    if (this.hasBeenShown) {
      this.logger.log('hasBeenShown');
      // Ensures that the original show is loaded/restored even thus some other show may have been loaded in between.
      // Ex. when browsing the page in a SPA where no surf iframe should be present,
      // the user might press the original button again with same BambuserLiveShopping instance.
      if (this.__restoreMinimized && this.__originalEventId) {
        this.loadShow(this.__originalEventId);
      }
      return false; // Already been shown, so it should already exist!?
    }

    const { href: currentUrl, search: currentSearch } = window.location;

    // For some rare cases when user would be surfing around on merchant website with a minimized player and decides to
    // start another show, it should not start playing in that iframe and create another player / surf iframe etc.
    // Instead we ensure to reload the whole page discarding this player and surf session with a new one for the target
    // show in top level window. We do this mainly prevent having a deep iframe nesting with surf iframe having a player
    // having a surf iframe... But not to also have more than one show active at one specific time.
    //
    // Normally we would like to start playing from the top frame, but for app dev env where the merchant page is placed
    // in a sub frame so we need to handle it differently:
    // 1. We don't want below block to execute on initial load (it's achived by relying on auto play parameter that is
    //    used during boot sequence and removed in surf behind iframes)
    // 2. We want the sub merchant page to behave like it's the top frame and below block should not to execute (it's
    //    achived by checking if parent window (app dev env) is top window or not)
    const currentWindowIsTop = window === window.top;
    const haveAutoPlayQueryParam = currentSearch.indexOf('autoplayLiveShopping=') > -1;
    const parentWindowIsTop = window.parent === window.top;
    const allowFixPlayFromNonTopFrame = !currentWindowIsTop && (!this._isAppDevEnv || (
      !haveAutoPlayQueryParam && !parentWindowIsTop
    ));
    if (allowFixPlayFromNonTopFrame) {
      const targetFrame = this._isAppDevEnv ? window.parent : window.top;
      const handledByParent = await new Promise((resolve) => {
        const requestId = Date.now();
        const config = this.config;
        const fromNode = !!config.node;
        delete config.node;

        // Register a post message handler to receive response from parent window.
        // But we are only interested in one specific event, whether our request to handle
        // opening the show was accepted.
        const parentMessageHandler = (event) => {
          const sourceIsParentWindow = event.source == targetFrame;
          if (!sourceIsParentWindow) {
            this.logger.log('show() parentMessageHandler() exit', event && event.data);
            return;
          }

          let message = null;
          try {
            message = JSON.parse(event.data);
          } catch (e) {
            this.logger.logProd('show() parentMessageHandler() error', e);
          }

          const shouldLogEvent = !message || message.eventName !== PLAYER_EVENTS.ON_PROGRESS;
          if (shouldLogEvent) this.logger.logDev('show() parentMessageHandler() Event from parent', message);

          if (message && message.eventName) {
            const eventName = message.eventName;
            if (eventName === SURF_FRAME_EVENTS.ACCEPTED_REQUEST_OPEN_SHOW && message.data.requestId === requestId) {
              window.clearTimeout(noResponseTimeout);
              window.removeEventListener('message', parentMessageHandler);
              resolve(true);
              return;
            }
          }
          this.logger.log('show() parentMessageHandler() Unhandled message', event);
        };
        parentMessageHandler.bind(this);
        window.addEventListener('message', parentMessageHandler, false);

        // We will wait some time for parent window to respond, otherwise raise a failure!
        const noResponseTimeout = window.setTimeout(() => {
          window.removeEventListener('message', parentMessageHandler);
          resolve(false);
        }, 1500);

        // Send request to parent window to open the show!
        const message = JSON.stringify({
          eventName: SURF_FRAME_EVENTS.REQUEST_OPEN_SHOW,
          data: { requestId, config, fromNode },
        });
        targetFrame.postMessage(message, '*');
      });

      // Player was shown by parent embed instance, bail!
      if (handledByParent) {
        this.logger.log('handledByParent');
        return;
      }

      // As a last resort, we change top url and includes autoplayLiveshopping param.
      // It may autoplay it may not, but user will just have to click once again there and it should work normally.
      // But that would never happen? But better safe than sorry.
      const querySeparator = currentSearch.length > 0 ? '&' : '?';
      return (targetFrame.location = `${currentUrl}${querySeparator}autoplayLiveShopping=${this.config.eventId}`);
    } else if (window.__currentBambuserLiveShoppingOverlayPlayer) {
      // load/restore a show when surfing without iframe in minimized state
      this.logger.log('load/restore a show when surfing without iframe in minimized state');
      const newEventId = this.config.eventId;
      const overlayPlayer = window.__currentBambuserLiveShoppingOverlayPlayer;
      const isNewShow = overlayPlayer.config.eventId !== newEventId;
      if (isNewShow) {
        // Mounting the API again will make new call to the mountPoint with the new eventId and make the
        // getProviderConfig method return a config specific to that new event
        overlayPlayer.mountPlayerAPI(newEventId, true);
      }

      // Fetch the provider config for the show which is about to load
      // and merge it with any new configs which might be passed in the request
      // (notably deeplink is an interesting property which we want the new player config
      // to properly update).
      const providerConfig = overlayPlayer.getProviderConfig();
      // Allow to disable autoplay from the player configuration (only if autoplay is allowed for the customer)
      if (this.config.allowShareAutoplay) {
        if (providerConfig.allowShareAutoplay === false) this.config.allowShareAutoplay = false;
      } else if (providerConfig.allowShareAutoplay === true) {
        console.warn(WARNING.PLAYER_CONFIG_IGNORED_BY_ORGANIZATION_CONFIG('allowShareAutoplay', 'autoplayHandler'));
      }
      overlayPlayer.config = Object.assign(providerConfig, this.config);

      return overlayPlayer.loadShow(newEventId, isNewShow);
    }

    this._emitEvent('load');

    const undesiredBrowser = isUndesiredBrowser({ _isEmbedContext: true });
    if (undesiredBrowser && this.config.eventId && !urlParams.has('autoplayLiveShopping')) {
      this.logger.log('history.pushState show()');
      const querySeparator = document.location.search.length > 0 ? '&' : '?';
      const urlWithAutoplay = `${currentUrl}${querySeparator}autoplayLiveShopping=${
        this.config.eventId
      }&bls_ubn=${encodeURIComponent(undesiredBrowser.name)}`;

      try {
        return await new Promise((resolve, reject) =>
          tryOpenInExternalBrowser(urlWithAutoplay, {
            onFail: () => reject(new Error('Failed to open in external browser')),
          }),
        );
      } catch (err) {
        // Open in browser failed: render player, let it do another attempt & eventually fail to an info screen.
        this.logger.logProd('embed.js failed to open external browser', err);
      }

      // Add the current page in the browser history, and update the browser URL with the autoplay URL
      window.history.pushState(null, null, urlWithAutoplay);
    }

    this.config.sourceUrl = currentUrl;

    const containerId = `livecommerce-${this.instanceId}-${new Date().getTime()}`;
    const surfContainerId = this.surf.containerId || `livecommerce-surf-${this.instanceId}-${new Date().getTime()}`;
    const backgroundContainerId = `livecommerce-bg-${this.instanceId}-${new Date().getTime()}`;

    this.loader = document.createElement('div');
    this.loader.classList.add('livecommerce-loader');
    window.setTimeout(() => this?.loader?.classList.add('fade-in'), 1000);

    // We use a separate div that fills the container while loading the iframe
    // otherwise click events on the container background is not detected on iOS (????!!)
    this.loaderWrapper = document.createElement('div');
    this.loaderWrapper.style.width = '100%';
    this.loaderWrapper.style.height = '100%';
    if (!this.config.disableClickOutsideBehavior) this.loaderWrapper.addEventListener('click', this.destroy.bind(this));

    const container = this?.config?.containerNode || document.createElement('div');
    container.setAttribute('id', containerId);
    container.setAttribute('data-bambuser-liveshopping-player-id', containerId);
    container.setAttribute('role', 'dialog');
    container.setAttribute('aria-modal', 'true');
    container.setAttribute('aria-label', 'Live video shopping');
    if (!this.config.containerNode) {
      container.appendChild(this.loaderWrapper);
      container.appendChild(this.loader);
    }

    this.styleElem = document.createElement('style');
    this.styleElem.setAttribute('type', 'text/css');

    this.activePlayerStyleElem = document.createElement('style');
    this.activePlayerStyleElem.setAttribute('type', 'text/css');

    const activePlayerStyles = getIOSActivePlayerStyles();
    let styles = getStyles(containerId, surfContainerId, backgroundContainerId, this.isDesktop);

    if (this.config.type === 'inline') {
      styles += getStylesInline(containerId, this.config.height || '550px');
    }

    'textContent' in this.activePlayerStyleElem
      ? (this.activePlayerStyleElem.textContent = activePlayerStyles)
      : (this.activePlayerStyleElem.styleSheet.cssText = activePlayerStyles);
    document.head.appendChild(this.activePlayerStyleElem);

    'textContent' in this.styleElem
      ? (this.styleElem.textContent = styles)
      : (this.styleElem.styleSheet.cssText = styles);
    document.head.appendChild(this.styleElem);

    // Add container differently depending on the type
    if (this.config.type === 'inline') {
      if (!this.config.node) {
        throw new BambuserLiveShoppingError("A 'node' must be specified for the inline type!");
      }
      container.classList.add('inline');
      this.config.node.appendChild(container);
    } else {
      container.style.position = 'fixed';
      container.style.bottom = 0;
      container.style.right = 0;
      container.style.width = '100%';
      container.style.height = '100%';
      //container.style.WebkitOverflowScrolling = 'touch';
      //container.style.overflow = 'auto';
      container.style.overscrollBehavior = 'none';
      // Power grab as some other component on customers site is at z-index: 2147483646. Lets hit 2147483647 (max)
      container.style.zIndex = 2147483647;

      if (!this.config.containerNode) {
        const playerContainerNode =
          (typeof this.config.playerContainerNode === 'object' &&
            this.config.playerContainerNode.tagName &&
            this.config.playerContainerNode) ||
          document.body;
        playerContainerNode.appendChild(container);
      }

      this._disableParentScroll();
      document.addEventListener('touchmove', (this.__preventBodyScroll = this._preventBodyScroll.bind(this)), {
        passive: false,
      });

      // Send any base page scroll position to player on iOS
      // (to allow syncing video top with browser top when writing in chat).
      // prettier-ignore
      if (isAppleMobileDevice() || isMacWithMultiTouch()) {
        window.visualViewport
          ? window.visualViewport.addEventListener('resize', this._viewportChangeHandler.bind(this))
          : window.addEventListener(
            'scroll', // Fallback for iOS version 12 and below
            (this.__pushPageScrollTopHandler = this._pushPageScrollTopHandler).bind(this),
          );
      }
    }

    this.hasBeenShown = true;
    this.__originalEventId = this.config.eventId;
    this.surf.containerId = surfContainerId;
    this.backgroundContainerId = backgroundContainerId;
    this.player.container = container;
    this.player.containerId = containerId;

    this.__cancelFullscreenListener = this.listenToPlayerFullscreenEvents();

    const isSiteReady = document.readyState !== 'loading';
    if (isSiteReady) {
      this.loadPlayer();
    } else {
      this.loadPlayerBound = this.loadPlayer.bind(this);
      window.addEventListener('DOMContentLoaded', this.loadPlayerBound);
    }

    return true;
  }

  _createPlayerFrame() {
    this.logger.log('_createPlayerFrame()');
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', this._getPlayerUrl());
    iframe.setAttribute('frameborder', 0);
    iframe.setAttribute('allowTransparency', true);
    iframe.setAttribute('allow', 'fullscreen; autoplay; web-share');
    iframe.setAttribute('title', 'Live video shopping');
    if (this.config.sandboxAttributes) iframe.setAttribute('sandbox', this.config.sandboxAttributes);
    if (this.config.credentiallessIframes) iframe.setAttribute('credentialless', true);
    return iframe;
  }

  // Enable new public api
  mountPlayerAPI(eventId, force = false) {
    const mountPoint = 'onBambuserLiveShoppingReady';
    try {
      this.playerDelegate.mount(window, mountPoint, eventId, force);
    } catch (e) {
      //this.logger.warnProd(`No ${mountPoint} method was found on window. Using default values for player.`);
    }
  }

  loadPlayer() {
    const { eventId } = this.config;

    if (!isValidEventIdFormat(eventId) && !isValidVideoIdFormat(eventId)) { 
      console.warn(`${eventId} does not seem to have a valid show or video Id format`);
    }

    this.logger.log('loadPlayer() eventId', eventId);

    window.addEventListener('message', (this.__receiveMessage = this._receiveMessage.bind(this)), false);

    if (typeof this.loadPlayerBound === 'function') {
      window.removeEventListener('DOMContentLoaded', this.loadPlayerBound);
      delete this.loadPlayerBound;
    }

    this.mountPlayerAPI(eventId);
    this.applyProviderConfig();

    if (this.config.checkoutOnCartClick) {
      const checkoutEventName = PLAYER_EVENTS.CHECKOUT.replace(PLAYER_EVENT_PREFIX, '');
      const checkoutOnCartClick = this.config.checkoutOnCartClick;

      if (!this.playerDelegate.getSupportedEvents().includes(checkoutEventName)) {
        let warningMsg = `When the checkoutOnCartClick configuration attribute is set to ${checkoutOnCartClick}`;
        warningMsg += 'an event listener for player.EVENT.CHECKOUT must be registered.';
        console.warn(warningMsg);
      }
    }

    if (this.config.containerNode) {
      this.player.frame = this.config.containerNode.getElementsByTagName('iframe')[0];
    } else {
      this.player.frame = this._createPlayerFrame();
      this.player.container.appendChild(this.player.frame);
      if (this.config.type === 'overlay') {
        window.__currentBambuserLiveShoppingOverlayPlayer = this;
      }
    }

    // Try ensure site doesn't have any other active playbacks as show now should have focus.
    this._pauseOtherPlaybacks();
  }

  applyProviderConfig() {
    const providerConfig = this.getProviderConfig();

    if (providerConfig._useExternalPlayerController) {
      this.player.container.style.backgroundColor = 'transparent';
    }

    // Allow to disable autoplay from the player configuration (only if autoplay is allowed for the customer)
    if (this.config.allowShareAutoplay) {
      if (providerConfig.allowShareAutoplay === false) this.config.allowShareAutoplay = false;
    } else if (providerConfig.allowShareAutoplay === true) {
      console.warn(WARNING.PLAYER_CONFIG_IGNORED_BY_ORGANIZATION_CONFIG('allowShareAutoplay', 'autoplayHandler'));
    }
    this.config = Object.assign(providerConfig, this.config);

    if (this.config.enableFirstPartyCookies) {
      this._trackingContext = Object.assign(this._trackingContext || {}, {
        userId: readCookie(TRACKING_COOKIES.USER_ID) || uuidv4(),
        sessionId: readCookie(TRACKING_COOKIES.SESSION_ID) || uuidv4(),
      });
      if (this.config.withGA) initGAConfig(this._trackingContext.userId);
    }
  }

  /**
   * Configuration is provided by hooking into the
   * window.onBambuserLiveShoppingReady callback and configuring
   * the player via the playerApi.
   */
  getProviderConfig() {
    const configTransformer = new BambuserLSLegacyConfigurationTransformer(this.playerDelegate);
    const providerConfig = this.playerDelegate.config.getConfigUsingTransformer(configTransformer);

    if (!this.playerDelegate.isMounted()) {
      // Missing onBambuserLiveShoppingReady
      // Make sure we don't use withoutProductDetails, due to the legacy reasons
      // It's important on the shopify integration side
      providerConfig.withoutProductDetails = false;
    }

    const { edgeSpacing = {} } = providerConfig || {};
    const spacing = this.isDesktop ? EDGE.SPACING_DESKTOP : EDGE.SPACING_MOBILE;
    const { top = spacing, right = spacing, bottom = spacing, left = spacing } = edgeSpacing[this.deviceType] || {};
    providerConfig.edgeSpacing = { top, right, bottom, left };

    return JSON.parse(JSON.stringify(providerConfig || {}));
  }

  _onPlayerApiEventHandlerAdded(eventName) {
    // Keep track of all event handlers added by the embedding page.
    // We'll send the names over the player once it has started.
    this._playerEventHandlers = this._playerEventHandlers || [];
    if (!this._playerEventHandlers.includes(eventName)) {
      this._playerEventHandlers.push(eventName);
    }
  }

  showPlayer() {
    this.logger.log('showPlayer()');
    if (this.player.frame) {
      if (document.contains(this.loaderWrapper)) {
        this.loaderWrapper.parentNode.removeChild(this.loaderWrapper);
        this.loaderWrapper = null;
      }

      // Log successful use of tryOpenInExternalBrowser()
      const urlParams = new URLSearchParams(window.location.search);
      const sourceBrowser = urlParams.get('bls_ubn');
      if (sourceBrowser) {
        const escapeMethod = urlParams.get('bls_ubm');
        this._sendMessageToPlayer(PLAYER_EVENTS.OPEN_EXTERNAL_BROWSER_SUCCESS, {
          bls_ubn: sourceBrowser,
          bls_ubm: escapeMethod,
        });
      }

      this.player.frame.classList.add('ready');
      this.loader?.classList.add('hidden');
      this.player.frame.setAttribute('data-test-id', 'iframePlayer');

      this._sendMessageToPlayer(PLAYER_EVENTS.EXTERNAL_EVENT_HANDLERS, { eventHandlers: this._playerEventHandlers });

      this._emitEvent(PLAYER_EVENTS_PUBLIC.READY);
    }
  }

  syncCartState() {
    this._emitEvent(PLAYER_EVENTS_PUBLIC.SYNC_CART_STATE, (data) => {
      // TODO: Implement more sophisticated cart sync with sending all the SKUs to player.
      // Currently we only clear the basket when it has reached zero items (ex. after checkout).
      if (data && data.count === 0) {
        this.updatePlayerCart({ count: 0 });
      }
    });
  }

  /**
   * Sends a message to the player to update cart contents
   * @param {object} data
   * @param {int} data.count number of items in cart
   * @param {array} data.items list of products (id's) that should be in cart
   */
  updatePlayerCart({ count, items }) {
    this._sendMessageToPlayer(PLAYER_EVENTS.DO_SYNC_CART_STATE, { count, items });
  }

  moveMiniPlayer() {
    const { x, y } = this.miniPlayer.position;
    this.player.container.style.transform = `translate(${x}px, ${y}px)`;
  }

  snapToBorders() {
    if (!SNAP.ENABLED) {
      return;
    }

    const {
      position,
      position: { x, y },
      snapLeftThreshold,
      snapTopThreshold,
      snapBottomThreshold,
      minX,
      minY,
      maxX,
      maxY,
      videoWidth,
      videoHeight,
    } = this.miniPlayer;
    let shouldSnap = false;

    const { edgeSpacing } = this.config;

    switch (this.config.minimizedPosition) {
      case MINIMIZED_POSITION.TOP_LEFT:
        if (x < minX + snapLeftThreshold) {
          position.x = minX + edgeSpacing.left;
          shouldSnap = true;
        } else if (x > edgeSpacing.right - snapLeftThreshold) {
          position.x = maxX - edgeSpacing.right - videoWidth;
          shouldSnap = true;
        }

        if (y < minY + snapTopThreshold) {
          position.y = minY + edgeSpacing.top;
          shouldSnap = true;
        } else if (y > maxY - snapBottomThreshold - videoHeight) {
          position.y = maxY - videoHeight - edgeSpacing.bottom;
          shouldSnap = true;
        }
        break;
      case MINIMIZED_POSITION.TOP_RIGHT:
        if (x < minX + snapLeftThreshold) {
          position.x = minX + edgeSpacing.left;
          shouldSnap = true;
        } else if (x > edgeSpacing.right - snapLeftThreshold) {
          position.x = maxX - edgeSpacing.right;
          shouldSnap = true;
        }

        if (y < minY + snapTopThreshold) {
          position.y = minY + edgeSpacing.top;
          shouldSnap = true;
        } else if (y > maxY - snapBottomThreshold) {
          position.y = maxY - edgeSpacing.bottom;
          shouldSnap = true;
        }
        break;
      case MINIMIZED_POSITION.BOTTOM_LEFT:
        if (x < minX + snapLeftThreshold) {
          position.x = minX + edgeSpacing.left;
          shouldSnap = true;
        } else if (x > edgeSpacing.right - snapLeftThreshold) {
          position.x = maxX - edgeSpacing.right - videoWidth;
          shouldSnap = true;
        }

        if (y < minY + snapTopThreshold) {
          position.y = minY + edgeSpacing.top;
          shouldSnap = true;
        } else if (y > edgeSpacing.bottom - snapBottomThreshold) {
          position.y = maxY - edgeSpacing.bottom;
          shouldSnap = true;
        }
        break;
      case MINIMIZED_POSITION.BOTTOM_RIGHT:
      default:
        if (x < minX + snapLeftThreshold) {
          position.x = minX + edgeSpacing.left;
          shouldSnap = true;
        } else if (x > edgeSpacing.right - snapLeftThreshold) {
          position.x = maxX - edgeSpacing.right;
          shouldSnap = true;
        }

        if (y < minY + snapTopThreshold) {
          position.y = minY + edgeSpacing.top;
          shouldSnap = true;
        } else if (y > edgeSpacing.bottom - snapBottomThreshold) {
          position.y = maxY - edgeSpacing.bottom;
          shouldSnap = true;
        }
        break;
    }

    if (shouldSnap) {
      this.player.container.classList.add('is-snapping');
      window.setTimeout(() => this.player.container.classList.remove('is-snapping'), 500);
      this.moveMiniPlayer();
    }
  }

  getQuickMoveOrientation(dx, dy) {
    const x = Math.abs(dx) > this.quickMove.thresholdX;
    const y = Math.abs(dy) > this.quickMove.thresholdY;
    if (!x) {
      if (!y) {
        return '';
      }
      return dy > 0 ? SOUTH : NORTH;
    }

    if (!y) {
      return dx < 0 ? EAST : WEST;
    }

    return dx < 0 ? (dy > 0 ? SOUTH_EAST : NORTH_EAST) : dy > 0 ? SOUTH_WEST : NORTH_WEST;
  }

  // prettier-ignore
  getQuickMoveTargetedPosition(orientation, { x, y }) {
    const { minX, minY, maxX, maxY } = this.miniPlayer;
    const { edgeSpacing } = this.config;

    switch (orientation) {
      case NORTH:
        if (x < minX + edgeSpacing.left) { // Quick move while sticking to the left
          return { x: minX + edgeSpacing.left, y: minY + edgeSpacing.top };
        }
        if (x > maxX - edgeSpacing.right) { // Quick move while sticking to the right
          return { x: maxX - edgeSpacing.right, y: minY + edgeSpacing.top };
        }
        return { x, y: minY + edgeSpacing.top };
      case SOUTH:
        if (x < minX + edgeSpacing.left) { // Quick move while sticking to the left
          return { x: minX + edgeSpacing.left, y: maxY - edgeSpacing.bottom };
        }
        if (x > maxX - edgeSpacing.right) { // Quick move while sticking to the right
          return { x: maxX - edgeSpacing.right, y: maxY - edgeSpacing.bottom };
        }
        return { x, y: maxY - edgeSpacing.bottom };
      case EAST:
        if (y < minY + edgeSpacing.top) { // Quick move while sticking to the top
          return { x: minX + edgeSpacing.left, y: minY + edgeSpacing.top };
        }
        if (y > maxY - edgeSpacing.bottom) { // Quick move while sticking to the bottom
          return { x: minX + edgeSpacing.left, y: maxY - edgeSpacing.bottom };
        }
        return { x: minX + edgeSpacing.left, y };
      case WEST:
        if (y < minY + edgeSpacing.top) { // Quick move while sticking to the top
          return { x: maxX - edgeSpacing.right, y: minY + edgeSpacing.top };
        }
        if (y > maxY - edgeSpacing.bottom) { // Quick move while sticking to the bottom
          return { x: maxX - edgeSpacing.right, y: maxY - edgeSpacing.bottom };
        }
        return { x: maxX - edgeSpacing.right, y };
      case NORTH_EAST:
        return { x: minX + edgeSpacing.left, y: minY + edgeSpacing.top };
      case SOUTH_EAST:
        return { x: minX + edgeSpacing.left, y: maxY - edgeSpacing.bottom };
      case SOUTH_WEST:
        return { x: maxX - edgeSpacing.right, y: maxY - edgeSpacing.bottom };
      case NORTH_WEST:
        return { x: maxX - edgeSpacing.right, y: minY + edgeSpacing.top.top };
    }
  }

  quickMoveReset() {
    this.quickMove.orientation = ''; // no more quick move in progress
    this.quickMove.trustIndex = 0;
    this.quickMove.detectionCount = 0;
  }

  onMiniPlayerMouseEnterLeave(event) {
    const mouseIsOver = event.type === 'mouseenter';
    if (this.isDesktop && this.miniPlayer.restoreButton) {
      this.miniPlayer.restoreButton.style.display = mouseIsOver ? 'flex' : 'none';
      this.miniPlayer.restoreButton.style.opacity = mouseIsOver ? 1 : 0;
    }

    if (this.playerSettings.playerVersion !== 2) {
      this.miniPlayer.closeButton.style.display = mouseIsOver ? 'flex' : 'none';
      this.miniPlayer.closeButton.style.opacity = mouseIsOver ? 1 : 0;
      const boxShadowOpacity = mouseIsOver ? 0.1 : 0.04;
      this.player.container.style.boxShadow =
        `0px 0px 1px rgba(0, 0, 0, ${boxShadowOpacity}), ` +
        `0px 4px 8px rgba(0, 0, 0, ${boxShadowOpacity}), ` +
        `0px 16px 24px rgba(0, 0, 0, ${boxShadowOpacity}), ` +
        `0px 24px 32px rgba(0, 0, 0, ${boxShadowOpacity})`;
    }
    this._sendMessageToPlayer(PLAYER_EVENTS.SET_VIEWPORT_STATE, { mouseIsOver });
  }

  _onActionKeyDown(e, callback) {
    isActionKey(e) && typeof callback === 'function' && callback();
  }

  onFirstElementKeyDown(e) {
    e.stopPropagation();
    if (!isBackNav(e)) return;
    // emits an event to the player to focus the first element inside the iframe when going backward with the Tab key
    this.onMiniPlayerMouseEnterLeave({ type: 'mouseleave' });
    this._sendMessageToPlayer(PLAYER_EVENTS.FOCUS_LAST_INSIDE_IFRAME);
    e.preventDefault();
  }

  onLastElementKeyDown(e) {
    e.stopPropagation();
    if (!isForwardNav(e)) return;
    // emits an event to the player to focus the last element inside the iframe when going forward with the Tab key
    this.onMiniPlayerMouseEnterLeave({ type: 'mouseleave' });
    this._sendMessageToPlayer(PLAYER_EVENTS.FOCUS_FIRST_INSIDE_IFRAME);
    e.preventDefault();
  }

  setMiniPlayerInitialPosition() {
    const { edgeSpacing } = this.config;
    switch (this.config.minimizedPosition) {
      case MINIMIZED_POSITION.TOP_LEFT:
        this.player.container.style.top = `${edgeSpacing.top}px`;
        this.player.container.style.left = `${edgeSpacing.left}px`;
        break;
      case MINIMIZED_POSITION.TOP_RIGHT:
        this.player.container.style.top = `${edgeSpacing.top}px`;
        this.player.container.style.right = `${edgeSpacing.right}px`;
        break;
      case MINIMIZED_POSITION.BOTTOM_LEFT:
        this.player.container.style.bottom = `${edgeSpacing.bottom}px`;
        this.player.container.style.left = `${edgeSpacing.left}px`;
        break;
      case MINIMIZED_POSITION.BOTTOM_RIGHT:
      default:
        this.player.container.style.bottom = `${edgeSpacing.bottom}px`;
        this.player.container.style.right = `${edgeSpacing.right}px`;
        break;
    }
  }

  unminimize() {
    this.__restoreMinimized && this.__restoreMinimized();
  }

  minimize() {
    this.logger.log('minimize()');

    if (!this.playerSettings.withMinimizeSupport) {
      throw new BambuserLiveShoppingError("Can't minimize player, not supported!");
    }

    exitFullscreen();

    this._trackOnInteraction('minimize');
    this._sendMessageToPlayer(PLAYER_EVENTS.SET_VIEWPORT_STATE, { minimized: true });

    const { surfBehindMode } = this.config;
    if (surfBehindMode !== MANUAL) {
      this.showSurf();
      this._disableParentScroll();
    } else {
      const viewport = document.querySelector('meta[name=viewport]');
      if (viewport && viewport.getAttribute('content').toLowerCase().indexOf('minimum-scale') === -1)
        // Fix mini-player position/scroll bug on chrome mobile
        viewport.setAttribute('content', `${viewport.getAttribute('content')}, minimum-scale=1`);
      this._restoreParentScroll();
    }

    this.player.container.setAttribute('aria-modal', 'false');

    const isPlayerV2 = this.playerSettings.playerVersion === 2;

    let restoreButton;
    if (!isPlayerV2) {
      if (this.isDesktop) {
        restoreButton = document.createElement('div');
        restoreButton.classList.add('livecommerce-restore-minimized');
        restoreButton.setAttribute('tabindex', '830');
        restoreButton.setAttribute('role', 'button');
        restoreButton.setAttribute('aria-label', 'Maximize live curation');
        restoreButton.innerHTML = BambuserLiveShopping.restoreIcon;
        restoreButton.addEventListener('keydown', this.onFirstElementKeyDown);
        this.player.container.appendChild(restoreButton);
        this.miniPlayer.restoreButton = restoreButton;
      }
    }

    const closeButton = document.createElement('div');
    closeButton.setAttribute('tabindex', '830');
    closeButton.setAttribute('role', 'button');
    closeButton.setAttribute('aria-label', 'Close live curation');
    closeButton.classList.add('livecommerce-close-minimized');
    closeButton.innerHTML = BambuserLiveShopping.closeIcon;
    if (isPlayerV2) {
      closeButton.classList.add('livecommerce-v2');
      closeButton.innerHTML = BambuserLiveShopping.closeIconV2;
    } else {
      closeButton.innerHTML = BambuserLiveShopping.closeIcon;
      if (!this.isDesktop) {
        closeButton.style.transition = `opacity ${MINIMIZE_TRANSITION_TIME}ms`;
        closeButton.addEventListener('keydown', this.onFirstElementKeyDown);
      }
    }

    closeButton.addEventListener('keydown', this.onLastElementKeyDown);
    this.player.container.appendChild(closeButton);
    this.miniPlayer.closeButton = closeButton;

    this.setMiniPlayerInitialPosition();

    this.player.container.style.transformOrigin = 'bottom right';
    this.player.container.style.overflow = 'hidden';
    this.player.container.style.boxShadow = '';

    if (this.isDesktop && !this.backgroundContainer) {
      this.backgroundContainer = document.createElement('div');
      this.backgroundContainer.setAttribute('id', this.backgroundContainerId);
      this.backgroundContainer.style.zIndex = 2147483647;
      this.backgroundContainer.style.transition = `opacity ${MINIMIZE_TRANSITION_TIME}ms`;
      this.player.container.parentNode.insertBefore(this.backgroundContainer, this.player.container);
    }

    Navigation.enableModeDetection();

    const calculateMinimizedView = (transitionHasCompleted) => {
      let videoWidth;
      let videoHeight;
      const videoOrientation = this.video.videoOrientation;

      let minWidth;
      let maxWidthFraction;

      const platform = this.isDesktop ? 'DESKTOP' : 'MOBILE';

      switch (this.config.miniplayerSize) {
        case MINIPLAYER_SIZE.LARGE:
          minWidth = MIN_WIDTH.LARGE[platform];
          maxWidthFraction = MAX_WIDTH_FRACTION.LARGE[platform];
          break;
        case MINIPLAYER_SIZE.SMALL:
        default:
          minWidth = MIN_WIDTH.SMALL[platform];
          maxWidthFraction = MAX_WIDTH_FRACTION.SMALL[platform];
          break;
      }

      if (videoOrientation === VIDEO_ORIENTATION.VERTICAL) {
        videoWidth = Math.min(window.innerWidth * maxWidthFraction, minWidth);
        videoHeight = videoWidth * (16 / 9);
      } else {
        videoHeight = Math.min(window.innerHeight * maxWidthFraction, minWidth);
        videoWidth = videoHeight * (16 / 9);
      }
      const { snapHorizontalThresholdRatio, snapVerticalThresholdRatio } = this.miniPlayer;
      const { edgeSpacing } = this.config;
      const snapLeftThreshold = snapHorizontalThresholdRatio
        ? window.innerWidth * snapHorizontalThresholdRatio - videoWidth / 2
        : edgeSpacing.left;
      const snapTopThreshold = snapVerticalThresholdRatio
        ? window.innerHeight * snapVerticalThresholdRatio - videoHeight / 2
        : edgeSpacing.top;
      const snapBottomThreshold = snapVerticalThresholdRatio
        ? window.innerHeight * snapVerticalThresholdRatio - videoHeight / 2
        : edgeSpacing.bottom;

      let minX, minY, maxX, maxY;

      switch (this.config.minimizedPosition) {
        case MINIMIZED_POSITION.TOP_LEFT:
          minX = -edgeSpacing.left;
          minY = -edgeSpacing.top;
          maxX = window.innerWidth - edgeSpacing.left;
          maxY = window.innerHeight - edgeSpacing.top;
          break;
        case MINIMIZED_POSITION.TOP_RIGHT:
          minX = -(window.innerWidth - videoWidth - edgeSpacing.right);
          minY = -edgeSpacing.top;
          maxX = edgeSpacing.right;
          maxY = window.innerHeight - videoHeight - edgeSpacing.top;
          break;
        case MINIMIZED_POSITION.BOTTOM_LEFT:
          minX = -edgeSpacing.left;
          minY = -(window.innerHeight - videoHeight - edgeSpacing.bottom);
          maxX = window.innerWidth - edgeSpacing.left;
          maxY = edgeSpacing.bottom;
          break;
        case MINIMIZED_POSITION.BOTTOM_RIGHT:
        default:
          minX = -(window.innerWidth - videoWidth - edgeSpacing.right);
          minY = -(window.innerHeight - videoHeight - edgeSpacing.bottom);
          maxX = edgeSpacing.right;
          maxY = edgeSpacing.bottom;
          break;
      }

      this.miniPlayer.snapLeftThreshold = snapLeftThreshold;
      this.miniPlayer.snapTopThreshold = snapTopThreshold;
      this.miniPlayer.snapBottomThreshold = snapBottomThreshold;

      this.miniPlayer.minX = minX;
      this.miniPlayer.minY = minY;
      this.miniPlayer.maxX = maxX;
      this.miniPlayer.maxY = maxY;

      this.miniPlayer.videoWidth = videoWidth;
      this.miniPlayer.videoHeight = videoHeight;

      const ensurePositionInsideViewportBounds = (isDragging = true) => {
        const { position } = this.miniPlayer;

        switch (this.config.minimizedPosition) {
          case MINIMIZED_POSITION.TOP_LEFT:
            if (-position.x > edgeSpacing.left) {
              position.x = minX; // mini-player going out by the left
            } else if (Math.abs(position.x) + videoWidth + edgeSpacing.left > window.innerWidth) {
              position.x = maxX - videoWidth; // mini-player going out by the right
            }

            if (position.y < minY) {
              position.y = isDragging ? minY : 0; // mini-player going out by the top
            } else if (position.y + videoHeight + edgeSpacing.top > window.innerHeight) {
              position.y = maxY - videoHeight; // mini-player going out by the bottom
            }
            break;
          case MINIMIZED_POSITION.TOP_RIGHT:
            if (Math.abs(position.x) + videoWidth + edgeSpacing.right > window.innerWidth) {
              position.x = minX; // mini-player going out by the left
            } else if (position.x > edgeSpacing.right) {
              position.x = maxX; // mini-player going out by the right
            }

            if (position.y < minY) {
              position.y = isDragging ? minY : 0; // mini-player going out by the top
            } else if (position.y > maxY) {
              position.y = maxY; // mini-player going out by the bottom
            }
            break;
          case MINIMIZED_POSITION.BOTTOM_LEFT:
            if (-position.x > edgeSpacing.left) {
              position.x = minX; // mini-player going out by the left
            } else if (Math.abs(position.x) + videoWidth + edgeSpacing.left > window.innerWidth) {
              position.x = maxX - videoWidth; // mini-player going out by the right
            }

            if (Math.abs(position.y) + videoHeight + edgeSpacing.bottom > window.innerHeight) {
              position.y = isDragging ? minY : minY + edgeSpacing.top; // mini-player going out by the top
            } else if (position.y > edgeSpacing.bottom) {
              position.y = maxY; // mini-player going out by the bottom
            }
            break;
          case MINIMIZED_POSITION.BOTTOM_RIGHT:
          default:
            if (Math.abs(position.x) + videoWidth + edgeSpacing.right > window.innerWidth) {
              position.x = minX; // mini-player going out by the left
            } else if (position.x > edgeSpacing.right) {
              position.x = maxX; // mini-player going out by the right
            }

            if (videoHeight + edgeSpacing.bottom > window.innerHeight + position.y) {
              position.y = isDragging ? minY : minY + edgeSpacing.top; // mini-player going out by the top
            } else if (position.y > edgeSpacing.bottom) {
              position.y = maxY; // mini-player going out by the bottom
            }
            break;
        }

        // if the mini-player is in the left part of the screen
        if (!isDragging && position.x < (maxX - minX) / -2) {
          position.x = minX + edgeSpacing.left; // we add the left margin
        }
      };

      ensurePositionInsideViewportBounds(false);

      const playerStyle = this.player.container.style;
      playerStyle.width = `${videoWidth}px`;
      playerStyle.height = `${videoHeight}px`;
      playerStyle.transform = `translate(${this.miniPlayer.position.x}px, ${this.miniPlayer.position.y}px)`;
      playerStyle.borderRadius = '8px';
      if ((this.isDesktop || transitionHasCompleted) && !isPlayerV2) {
        playerStyle.boxShadow =
          '0px 0px 1px rgba(0, 0, 0, 0.04), ' +
          '0px 4px 8px rgba(0, 0, 0, 0.04), ' +
          '0px 16px 24px rgba(0, 0, 0, 0.04), ' +
          '0px 24px 32px rgba(0, 0, 0, 0.04)';
      }

      this._miniPlayerDragEnd = () => {
        this.snapToBorders();
        if (this.config.surfBehindMode === MANUAL) this._restoreParentScroll();
      };

      this.__handleMoveMinimizedPlayer = ({ dx, dy }) => {
        if (this.config.surfBehindMode === MANUAL) this._disableParentScroll();
        const { requestedPosition, position } = this.miniPlayer;

        // If the positions are not in sync before to move, we reset the requested position
        // This happen when we continue to drag while the player is already touching a page border
        // It avoids to have to wait before to be able to unstick the player from a border
        if (requestedPosition.x !== position.x) {
          requestedPosition.x = position.x;
        }
        if (requestedPosition.y !== position.y) {
          requestedPosition.y = position.y;
        }

        // Storing the requested position in a separate variable and using the actually allowed position in another
        // allows us to stop moving the player outside viewport bounds but to continue moving when finger/mouse returns
        // back to an allowed position. Would we only use one variable that would reset to allowed position it would
        // still work but would offset the finger/mouse from the original dragging point when returning back to allowed
        // dragging area and create a very confusing drag UX.
        requestedPosition.x += dx;
        requestedPosition.y += dy;
        position.x = requestedPosition.x;
        position.y = requestedPosition.y;

        ensurePositionInsideViewportBounds(true);
        this.moveMiniPlayer();

        if (!this.quickMove.enabled) {
          return;
        }

        // Quick move in progress
        if (this.quickMove.orientation) {
          // While there is quick moves detected (+50ms margin), we look for the move with the biggest trust index:
          // We track the biggest value abs(dx)+abs(dy) in order to have a better detection of the expected orientation
          const trustIndex = Math.abs(dx) + Math.abs(dy);
          if (trustIndex <= this.quickMove.trustIndex) {
            return;
          }
          this.quickMove.trustIndex = trustIndex;
        }

        this.quickMove.orientation = this.getQuickMoveOrientation(dx, dy);

        if (this.quickMove.orientation) {
          this.logger.log('Quick move detected', dx, dy);
          this.quickMove.trustIndex = Math.abs(dx) + Math.abs(dy);
          this.quickMove.detectionCount++;
          this.quickMove.targetedPosition = this.getQuickMoveTargetedPosition(
            this.quickMove.orientation,
            requestedPosition,
          );

          const quickMove = () => {
            // False positive detection
            if (this.quickMove.detectionCount < QUICK_MOVE.MIN_COUNT_THRESHOLD) {
              this.logger.log('Quick move false positive detected');
              this.quickMoveReset();
              return;
            }

            const { x, y } = this.quickMove.targetedPosition;
            this.logger.log('Quick move action', dx, dy);
            position.x = x;
            position.y = y;
            ensurePositionInsideViewportBounds(true);
            this.moveMiniPlayer();
            this.quickMoveReset();
          };
          quickMove.bind(this);

          if (this.quickMove.timeout) {
            window.clearTimeout(this.quickMove.timeout);
          }
          this.quickMove.timeout = window.setTimeout(quickMove, QUICK_MOVE.DETECTION_DURATION);
        }
      };
    };

    this.__resetMinimized = () => {
      this.logger.log('__resetMinimized()');
      // Reset minimized styles (except the transition property so it can animate back nicely).
      if (!this.isDesktop) {
        this.player.container.style.transition = `transform ${MINIMIZE_TRANSITION_TIME}ms`;
      }

      this.player.container.style.top = '';
      this.player.container.style.left = '';
      this.player.container.style.bottom = 0;
      this.player.container.style.right = 0;

      this.player.container.style.width = '100%';
      this.player.container.style.height = '100%';
      this.player.container.style.transform = '';
      this.player.container.style.borderRadius = '';
      this.player.container.style.boxShadow = '';
      this.player.container.style.overflow = '';
      this.player.container.setAttribute('aria-modal', 'true');
      if (this.isDesktop) {
        this.backgroundContainer.style.display = '';
        this.backgroundContainer.style.opacity = 1;
        if (this.miniPlayer.restoreButton) this.player.container.removeChild(this.miniPlayer.restoreButton);
      }
      this.player.container.removeChild(this.miniPlayer.closeButton);
      this._disableParentScroll();
      delete this.__handleMoveMinimizedPlayer;
      window.removeEventListener('resize', this.__calculateMinimizedView);
      if (this.isDesktop) {
        this.player.container.removeEventListener('mouseenter', this.onMiniPlayerMouseEnterLeave);
        this.player.container.removeEventListener('mouseleave', this.onMiniPlayerMouseEnterLeave);
      }

      delete this.__calculateMinimizedView;
      delete this.__resetMinimized;
      delete this.__restoreMinimized;
      delete this.__closeMinimized;
    };

    const resetMinimized = this.__resetMinimized;

    this.__restoreMinimized = () => {
      this.logger.log('__restoreMinimized()');
      this._trackOnInteraction('restore');
      Navigation.disableModeDetection();
      this._sendMessageToPlayer(PLAYER_EVENTS.SET_VIEWPORT_STATE, {
        minimized: false,
        mouseIsOver: false,
      });

      // TODO: Investigate if we can solve this problem in some other way. Ex. is it viable that merchant would emit
      // some event with postMessage that we can pick up from the surf iframe?
      this.syncCartState();

      resetMinimized();

      if (!this.surf.frame && !this.activePlayerStyleElem.parentNode) {
        document.head.appendChild(this.activePlayerStyleElem);
      }

      // Try ensure site doesn't have any other active playbacks
      // as show should have focus again when restored from minimized mode.
      this._pauseOtherPlaybacks();

      delete this.__restoreMinimized;
    };

    if (this.isDesktop && this.miniPlayer.restoreButton) {
      this.miniPlayer.restoreButton.addEventListener('click', this.__restoreMinimized.bind(this));
      this.miniPlayer.restoreButton.addEventListener('keydown', (e) =>
        this._onActionKeyDown(e, this.__restoreMinimized),
      );
    }

    this.__closeMinimized = () => {
      this._trackOnInteraction('close', { actionOrigin: 'miniplayer' });
      if (this.__windowUnloadHandler) {
        window.removeEventListener('unload', this.__windowUnloadHandler);
        delete this.__windowUnloadHandler;
      }
      window.removeEventListener('resize', this.__calculateMinimizedView);
      this._emitEvent(PLAYER_EVENTS_PUBLIC.CLOSE);
      this.destroy();
    };

    this.miniPlayer.closeButton.addEventListener('click', this.__closeMinimized.bind(this));
    this.miniPlayer.closeButton.addEventListener('keydown', (e) => this._onActionKeyDown(e, this.__closeMinimized));

    // Post animation steps
    // * Restore button didn't look nice during animation (very large->small, but maybe it can be fixed somehow).
    // * Animating without a box shadow creates a much smoother animation - add the box shadow afterwards.
    // * Prepare for receiving resize events and recalculate minimzed view without any animations.

    // Check if device have supports touch input. Don't hide restore/close controls
    // which is hides behind the hover effect if device supports touch.
    const supportsTouch = 'ontouchstart' in window || window.navigator.msMaxTouchPoints;

    window.setTimeout(() => {
      if (this.isDesktop && !supportsTouch) {
        this.player.container.addEventListener('mouseenter', this.onMiniPlayerMouseEnterLeave);
        this.player.container.addEventListener('mouseleave', this.onMiniPlayerMouseEnterLeave);
      } else {
        this.miniPlayer.closeButton.style.display = 'flex';
        this.miniPlayer.closeButton.style.opacity = 1;
      }
      this.player.container.style.transition = '';
      calculateMinimizedView(true);
    }, MINIMIZE_TRANSITION_TIME);

    this.__calculateMinimizedView = calculateMinimizedView.bind(this);
    window.addEventListener('resize', this.__calculateMinimizedView);

    if (this.isDesktop) {
      // Next frame please...
      window.setTimeout(() => {
        this.backgroundContainer.style.opacity = 0;
        this.player.container.style.backgroundColor = 'transparent';
      }, 0);
    } else {
      const playerStyle = this.player.container.style;
      playerStyle.transition = `transform ${MINIMIZE_TRANSITION_TIME}ms, border-radius ${MINIMIZE_TRANSITION_TIME}ms`;
    }

    this.__calculateMinimizedView();

    if (!this.surf.frame && this.activePlayerStyleElem.parentNode) {
      this.activePlayerStyleElem.parentNode.removeChild(this.activePlayerStyleElem);
    }
  }

  async enterPlayerFullscreen() {
    if (!this.player.container) return;
    try {
      await enterFullscreen(this.player.container);
    } catch (error) {
      console.log(error);
    }
  }

  async exitPlayerFullscreen() {
    try {
      await exitFullscreen();
    } catch (error) {
      console.log(error);
    }
  }

  listenToPlayerFullscreenEvents() {
    if (!this.player.container) return;
    return listenToFullscreenEvents(this.player.container, () => {
      this._sendMessageToPlayer(PLAYER_EVENTS.SET_VIEWPORT_STATE, { fullscreen: isFullscreen() });
    });
  }

  surfTo(url) {
    this.logger.log('surfTo(url)', url);
    if (!this.playerSettings.withMinimizeSupport) {
      throw new BambuserLiveShoppingError("Can't surf to url behind player, not supported!");
    }

    const { surfBehindMode } = this.config;

    if (surfBehindMode === MANUAL) {
      if (url !== window.location.href) {
        this._emitEvent(PLAYER_EVENTS_PUBLIC.NAVIGATE_BEHIND_TO, { url });
        this._emitEvent('surf-behind-to', { url });
      }
      return;
    }

    const surfFrame = this.getSurfFrame();
    surfBehindMode === SRCDOC ? surfWithSrcdocFrame(surfFrame, { url }) : surfFrame.setAttribute('src', url);

    this._emitEvent(PLAYER_EVENTS_PUBLIC.NOTIFY_URL_CHANGE, { url });

    // Try ensure site doesn't have any other active playbacks
    // as it will be kinda impossible to pause them with the surf iframe above otherwise.
    this._pauseOtherPlaybacks();
  }

  // TODO: Copy scrollbar fixes from Live Meetings surf behind popup impl.
  showSurf() {
    this.logger.log('showSurf()');
    this.surf.container.style.visibility = 'visible';
    this.surf.container.style.pointerEvents = 'auto';
    this._disableParentScroll();
    // Ensure any scrollbars are hidden on desktop browsers (mostly linux?)
    if (this.isDesktop) {
      document.documentElement.style.overflow = 'hidden';
    }
    // Ensure all main elements on site is either hidden/visible when iframe is visible/hidden.
    // We do this because it doesn't seem that on iOS body overflow: hidden works and we don't want to
    // be able to scroll into the underlying page (that the iframe should cover).
    // Also it may improve performance to hide all elements that is hidden anyway...
    // TODO: Create a style node which writes this and uses "!important" instead so we can be totally sure.
    const notSurfContainer = `:not(#${this.surf.containerId})`;
    const notPlayerContainer = `:not(#${this.player.containerId})`;
    const notBackgroundContainer = `:not(#${this.backgroundContainerId})`;
    const querySelector = `body > *${notSurfContainer}${notPlayerContainer}${notBackgroundContainer}`;
    const elements = document.querySelectorAll(querySelector);

    const playerContainerNodeTopParent = getNodeTopParent(this.config.playerContainerNode);
    // eslint-disable-next-line
    for (const element of elements) {
      if (element === playerContainerNodeTopParent) continue;
      const styles = window.getComputedStyle && window.getComputedStyle(element);
      const isHidden = (styles && styles.display === 'none') || element.style.display === 'none';
      if (!isHidden) {
        element.setAttribute('data-surf-behind-hidden', '1');
        element.style.display = 'none';
      }
    }

    // REMARK: Fix some weird rendering issues with iOS and elements with fixed positioning inside the surf iframe.
    // By specifying this styling value on page loads it can later be removed or changed by the page inside the iframe
    // and everything would work as expected, without this the UI could potentially be unusable other wise...
    if (isAppleMobileDevice() || isMacWithMultiTouch()) {
      window.setTimeout(() => {
        try {
          const { frame: surfFrame } = this.surf;
          const surfDocument = surfFrame.contentDocument || surfFrame.contentWindow.document;
          if (surfDocument?.body.style.position) {
            surfDocument.body.style.position = 'unset';
          }
          surfDocument.body.style.position = 'initial';
        } catch (e) {
          /* Just swallow any cross-domain errors... */
        }
      }, 100);
    }
  }

  hideSurf() {
    this.logger.log('hideSurf()');
    this.surf.container.style.visibility = 'hidden';
    this.surf.container.style.pointerEvents = 'none';
    this._restoreParentScroll();
    if (this.isDesktop) {
      document.documentElement.style.overflow = '';
    }
    const elements = document.querySelectorAll('[data-surf-behind-hidden]');
    // eslint-disable-next-line
    for (const element of elements) {
      element.removeAttribute('data-surf-behind-hidden');
      element.style.display = '';
    }
  }

  onSurfLoad() {
    let surfDocument;
    try {
      const { frame: surfFrame, skipNextBrowserHistoryEntry } = this.surf;
      const surfWindow = surfFrame.contentWindow;
      surfDocument = surfFrame.contentDocument || surfFrame.contentWindow.document;
      const { surfBehindMode } = this.config;
      const surfHref = surfWindow.location.href;
      const url = surfHref === 'about:srcdoc' && surfDocument.baseURI ? surfDocument.baseURI : surfHref;

      // prettier-ignore
      this.logger.log(
        'onSurfLoad() config.surfBehindMode', surfBehindMode,
        'surfHref', surfHref, // cross domain issue
        'surfDocument.baseURI', surfDocument.baseURI, // cross domain issue
        'skipNextBrowserHistoryEntry', skipNextBrowserHistoryEntry,
        'url', url,
      );

      if (
        surfBehindMode !== MANUAL &&
        !skipNextBrowserHistoryEntry &&
        url !== 'about:blank' &&
        url !== 'about:srcdoc'
      ) {
        // pushState & replaceState doesn't really support the title (2nd) parameter, so we force it manually
        document.title = surfDocument.title;

        // Browsers remember the iframe navigation in back/forward buttons.
        // We add "Bambuser Live Shopping flag" to indicate that this is a navigation happening inside the iframe so
        // we can detect when to show/hide iframe when moving back/forward in history.
        if (surfBehindMode === SRCDOC) {
          // Add the current page in the browser history, and update the browser URL with the autoplay URL
          window.history.pushState({ bambuserLiveShopping: true }, surfDocument.title, url);
          this.logger.log('history.pushState surfBehindMode', surfBehindMode, 'url', url);
        } else {
          // Update the browser URL with the product page URL, but don't alter the browser history
          window.history.replaceState({ bambuserLiveShopping: true }, surfDocument.title, url);
          this.logger.log('history.replaceState surfBehindMode', surfBehindMode, 'url', url);
        }
      } else {
        this.logger.log('NO history.replaceState surfBehindMode', surfBehindMode, 'url', url);
      }
    } catch (e) {
      console.warn('Unable to sync browser url', e.message);
    }

    this.surf.skipNextBrowserHistoryEntry = false; // reset the value

    // REMARK: Fix some weird rendering issues with iOS and elements with fixed positioning inside the surf iframe.
    // By specifying this styling value on page loads it can later be removed or changed by the page inside the iframe
    // and everything would work as expected, without this the UI could potentially be unusable other wise...
    if (isAppleMobileDevice() || isMacWithMultiTouch()) {
      try {
        if (!surfDocument?.body.style.position) {
          surfDocument.body.style.position = 'initial';
        }
      } catch (e) {
        /* Just swallow any cross-domain errors... */
      }
    }
  }

  onPopstate(event) {
    // prettier-ignore
    this.logger.log(
      'popstate(event) event.state', event.state,
      'surfBehindMode', this.config.surfBehindMode,
      'state.bambuserLiveShopping', event.state && event.state.bambuserLiveShopping,
    );

    // When saved state includes bambuserLiveShopping, we should show the iframe,
    // otherwise it's an history entry happening outside the iframe.
    if (event.state && event.state.bambuserLiveShopping) {
      this.showSurf();
      if (this.config.surfBehindMode === SRCDOC) {
        this.surf.skipNextBrowserHistoryEntry = true;
        surfWithSrcdocFrame(this.getSurfFrame(), window.location.href);
      }
    } else {
      // We are back on the landing page (where the player is minified for the 1st time)
      document.title = this.landingPagePageTitle; // we restore the landing page title
      this.hideSurf();
    }
  }

  initializeSurf() {
    this.logger.log('initializeSurf()');

    window.addEventListener('popstate', this.onPopstate.bind(this));
    window.addEventListener(
      'message',
      (this.__receiveSurfFrameMessage = this._receiveSurfFrameMessage.bind(this)),
      false,
    );

    const surfFrame = document.createElement('iframe');
    surfFrame.style.width = '100%';
    surfFrame.style.height = '100%';
    surfFrame.setAttribute('frameborder', '0');
    surfFrame.setAttribute('title', 'Surf behind live video shopping');
    if (this.config.sandboxAttributes) surfFrame.setAttribute('sandbox', this.config.sandboxAttributes);
    if (this.config.credentiallessIframes) surfFrame.setAttribute('credentialless', true);
    surfFrame.onload = this.onSurfLoad.bind(this);
    this.surf.frame = surfFrame;

    const surfContainer = document.createElement('div');
    surfContainer.style.visibility = 'hidden';
    surfContainer.style.pointerEvents = 'none';
    surfContainer.style.position = 'fixed';
    surfContainer.style.zIndex = 2147483647;
    surfContainer.style.top = 0;
    surfContainer.style.left = 0;
    surfContainer.style.bottom = 0;
    surfContainer.style.right = 0;
    surfContainer.style.width = '100%';
    surfContainer.style.height = '100%';
    surfContainer.style.overscrollBehavior = 'inherit';
    surfContainer.setAttribute('id', this.surf.containerId);
    surfContainer.appendChild(surfFrame);
    this.surf.container = surfContainer;

    this.config.type === 'inline'
      ? document.body.appendChild(surfContainer)
      : this.player.container.parentNode.insertBefore(surfContainer, this.player.container);
  }

  showSurfTimeout() {
    this.logger.log('popstate timeout showSurf(true)');
    this.showSurf();
  }

  getSurfFrame() {
    this.logger.log('getSurfFrame()');
    if (!this.surf.frame) {
      this.initializeSurf();
    }
    return this.surf.frame;
  }

  showCheckout(url) {
    if (this.playerSettings.preferNewTabCheckout) {
      document.addEventListener(
        'visibilitychange',
        (this.__onReturnToWindow = () => {
          if (!document.hidden) {
            document.removeEventListener('visibilitychange', this.__onReturnToWindow);
            delete this.__onReturnToWindow;
            this.syncCartState();
          }
        }).bind(this),
      );
      window.open(url, '_blank');
    } else {
      this.surfTo(url);
      this.minimize();
    }
  }

  /**
   * Specifies if tracking cookies should be updated
   * @returns {boolean}
   */
  get shouldUpdateTrackingCookies() {
    return this.config.enableFirstPartyCookies && this._trackingContext;
  }

  /**
   * Returns device type for current device
   * @returns {'desktop' | 'mobile'}
   */
  get deviceType() {
    return this.isDesktop ? 'desktop' : 'mobile';
  }

  /**
   * Get version of the player
   * @return {number} player version
   */
  get playerVersion() {
    const v2 = urlParams.get('v2');
    if (v2 === '1' || v2 === 'true') return 2;
    if (v2 === '0' || v2 === 'false') return 1;
    if (window.__playerVersion === 2) return 2;
    if (this.playerSettings?.playerVersion) return this.playerSettings.playerVersion;
    return null; // Unless forced via query parameter, the player itself will insist on a version
  }

  /**
   * Get if player is in dashboard
   * @return {number} is dashboard
   */
  get isDashboard() {
    return window.__isDashboard === 1 ? 1 : null;
  }

  /**
   * Update tracking with custom tracking tags
   * @param {array} trackingTags - array of tracking tags [{key: 'myTag', value: 'myValue'}]
   */
  setTrackingTags(trackingTags = []) {
    if (!Array.isArray(trackingTags)) {
      throw new BambuserLiveShoppingError('Tracking tags must be an array of key value pairs');
    }
    if (trackingTags.length > 20) {
      console.warn('Bambuser limits number of tracking tags to 20. Excess tags will be ignored');
      trackingTags = trackingTags.slice(0, 20);
    }
    // Validate tags, remove invalid ones and duplicates
    trackingTags = Array.from(
      trackingTags.reduce((map, tag) => {
        const { key, value } = tag;
        if (!key || typeof key !== 'string') {
          console.warn('Bambuser rejected tracking tag with invalid or missing key', tag);
          return map;
        }
        // Only allow string, number or boolean values
        if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'boolean') {
          console.warn('Bambuser rejected tracking tag with invalid or missing value', tag);
          return map;
        }
        if (typeof value === 'string' && new Blob([value]).size > 1024) {
          console.warn('Bambuser rejected tracking tag exceeding allowed size limit of 1KB', tag);
          return map;
        }
        // Make sure there are no duplicates
        return map.set(key, {
          key,
          value,
        });
      }, new Map()).values(),
    );
    this._sendMessageToPlayer(PLAYER_EVENTS.TRACKING_TAGS, {
      trackingTags,
    });
    this.trackingTags = trackingTags;
  }

  /**
   * Update products with fresh data
   * @param {[object|SchemaModel]} products
   */
  updateProducts(products) {
    const validProducts = [];
    // eslint-disable-next-line
    for (const product of products) {
      if (product instanceof SchemaModel) {
        try {
          validProducts.push(product.toObject());
        } catch (e) {
          console.info(`Ignoring invalid product: ${e.message}`);
        }
      } else {
        validProducts.push(product);
      }
    }

    if (this.debug) {
      if (!window._bambuserHydratedProductsDebugStorage) window._bambuserHydratedProductsDebugStorage = {};
      for (const product of validProducts) {
        window._bambuserHydratedProductsDebugStorage[product._id] = product;
      }
    }

    this._sendMessageToPlayer(PLAYER_EVENTS.PROVIDE_PRODUCT_DATA, { products: validProducts });
  }

  /**
   * Hide all user interfaces from the player
   */
  hideUI(uiSections) {
    this._sendMessageToPlayer(PLAYER_EVENTS.HIDE_UI, { uiSections });
  }

  /**
   * Show all user interfaces of the player
   */
  showUI() {
    this._sendMessageToPlayer(PLAYER_EVENTS.SHOW_UI);
  }

  /**
   * Tell player timeline to play
   */
  play() {
    this._sendMessageToPlayer(PLAYER_EVENTS.PLAY);
  }

  /**
   * Tell player timeline to pause
   */
  pause() {
    this._sendMessageToPlayer(PLAYER_EVENTS.PAUSE);
  }

  /**
   * Tell player to show product list
   */
  showProductList() {
    this._sendMessageToPlayer(PLAYER_EVENTS.SHOW_PRODUCT_LIST);
  }


  /**
   * Tell player to hide product list
   */
  hideProductList() {
    this._sendMessageToPlayer(PLAYER_EVENTS.HIDE_PRODUCT_LIST);
  }

  playerApiCall(eventName, data = {}, waitForResponse = false) {
    return new Promise((resolve, reject) => {
      if (!eventName) {
        return reject(new Error('eventName required'));
      }
      if (waitForResponse) {
        const responseId = `response-${eventName}:${Date.now()}`;
        if (!data) data = {};
        data.responseId = responseId;
        this._pendingRequestsToPlayer[responseId] = (response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        };
      } else {
        resolve(null);
      }
      this._sendMessageToPlayer(eventName, data);
    });
  }

  /**
   * Tell player timeline to seek to percentange
   */
  seekToPercent(seekToPercentage) {
    this._sendMessageToPlayer(PLAYER_EVENTS.SEEK_TO_PERCENT, { seekToPercentage });
  }

  /**
   * Tell player timeline to mute
   */
  mute() {
    this._sendMessageToPlayer(PLAYER_EVENTS.MUTE);
  }

  /**
   * Tell player timeline to unmute
   */
  unmute() {
    this._sendMessageToPlayer(PLAYER_EVENTS.UNMUTE);
  }

  /**
   * Factory for product generator
   * - Creates a new product instance
   * @param {string} productId
   * @return {LocalizedProduct}
   */
  createProduct(productId) {
    return CreateProduct(productId);
  }

  destroy() {
    this.logger.log('destroy()');

    // TODO: remove autoplayLiveShopping from URL, user doesn't want this param property anymore
    // const urlParams = new URLSearchParams(window.location.search);
    // if (urlParams.has('autoplayLiveShopping')) {}

    if (this.__resetMinimized) {
      this.__resetMinimized(true);
      delete this.__resetMinimized;
    }

    if (this.__restoreMinimized) {
      delete this.__restoreMinimized;
    }

    if (this.__closeMinimized) {
      delete this.__closeMinimized;
    }

    if (this.__calculateMinimizedView) {
      delete this.__calculateMinimizedView;
    }

    if (this.__cancelFullscreenListener) {
      this.__cancelFullscreenListener();
      delete this.__cancelFullscreenListener;
    }
    this._destroyHtmlNodes();

    if (this.__receiveMessage) {
      window.removeEventListener('message', this.__receiveMessage, false);
      delete this.__receiveMessage;
    }

    // REMARK: Surf frame message listener is not removed by choice.
    // As the surf frame is kept to allow surfing after closing a show, we may still need to open
    // new shows via this embed instance.
    // if (this.__receiveSurfFrameMessage) {}

    if (this.__preventBodyScroll) {
      document.removeEventListener('touchmove', this.__preventBodyScroll, { passive: false });
      delete this.__preventBodyScroll;
      this._restoreParentScroll();
    }

    if (this.__pushPageScrollTopHandler) {
      window.removeEventListener('scroll', this.__pushPageScrollTopHandler);
      delete this.__pushPageScrollTopHandler;
      if (this.__pushPageScrollTopTimeout) {
        window.clearTimeout(this.__pushPageScrollTopTimeout);
        delete this.__pushPageScrollTopTimeout;
      }
    }

    this._clearCookieRefreshInterval();
    if (this._trackingContext?.showWasPlayed) {
      this._updateTrackingCookies();
    }

    if (window.__currentBambuserLiveShoppingOverlayPlayer === this) {
      delete window.__currentBambuserLiveShoppingOverlayPlayer;
    }
    if (this.config._useExternalPlayerController) {
      this.playerDelegate.resetPlayerControllers();
    }

    for (const [ pendingRequestKey, pendingRequest ] of Object.entries(this._pendingRequestsToPlayer)) {
      pendingRequest(undefined, 'The request is rejected due to the player being closed.');
      delete this._pendingRequestsToPlayer[pendingRequestKey];
    }

    this.hasBeenShown = false;
  }

  updateTrackingContextData(newTrackingContextData = {}) {
    if (!this.shouldUpdateTrackingCookies) return;
    Object.assign(this._trackingContext, newTrackingContextData);
    this._updateTrackingCookies();
  }

  _getPlayerUrl() {
    return generatePlayerIframeUrlForEmbedInstance(this);
  }

  _destroyHtmlNodes() {
    this.logger.log('_destroyHtmlNodes()');

    if (this.player.container) {
      if (document.contains(this.loaderWrapper)) {
        this.loaderWrapper.parentNode.removeChild(this.loaderWrapper);
        this.loaderWrapper = null;
      }
      if (!this.config.containerNode) {
        this.player.container.parentNode.removeChild(this.player.container);
        this.player.container = null;
        this.player.frame = null;
        this.loader = null;
        delete this.player.containerId;
      }
    }

    if (this.backgroundContainer) {
      this.backgroundContainer.parentNode.removeChild(this.backgroundContainer);
      this.backgroundContainer = null;
      delete this.backgroundContainerId;
    }

    // REMARK: Surf container is not removed by choice.
    // The surf frame needs to be kept to allow surfing after closing a show.
    // if (this.surf.container) {}

    if (this.activePlayerStyleElem && !this.surf.container) {
      if (this.activePlayerStyleElem.parentNode) {
        this.activePlayerStyleElem.parentNode.removeChild(this.activePlayerStyleElem);
      }
      this.activePlayerStyleElem = null;
    }

    if (this.styleElem && !this.surf.container) {
      this.styleElem.parentNode.removeChild(this.styleElem);
      this.styleElem = null;
    }
  }

  _preventBodyScroll(rawEvent) {
    this.logger.log('_preventBodyScroll(rawEvent)', rawEvent);

    const e = rawEvent || window.event;
    // Don't prevent scrolling when having minimized player state and surf behind without an iframe.
    if (this.__restoreMinimized && !this.surf.frame) {
      return true;
    }

    // Do not prevent if the event has more than one touch
    // (usually meaning this is a multi touch gesture like pinch to zoom).
    if (e.touches.length > 1) {
      return true;
    }

    if (e.preventDefault) {
      e.preventDefault();
    }

    return false;
  }

  _receiveMessage(event) {
    // If source is not the player or parent app framework dev env
    const playerSource = (this.player.frame && this.player.frame.contentWindow === event.source);
    const devEnvParentSource = (event.source === window.parent && this._isAppDevEnv);
    if (!playerSource && !devEnvParentSource) {
      this.logger.log('_receiveMessage() exit', event && event.data);
      return;
    }

    // App framework messages proxy layer passing data between player and app dev env
    if (
      this._isAppDevEnv &&
      typeof event.data === 'object' &&
      typeof event.data.eventType === 'string' &&
      event.data.eventType.startsWith('bambuserAppDev:')
    ) {
      if (event.source === window.parent) {
        // Forward app dev env message to player
        this.player.frame.contentWindow.postMessage(event.data, '*');
      } else if (event.source === this.player.frame.contentWindow) {
        // Forward player message to app dev env
        window.parent.postMessage(event.data, '*');
      }
      return;
    }

    let message = null;
    try {
      message = typeof event.data === 'object' ? event.data : JSON.parse(event.data);
    } catch (e) {
      this.logger.logProd('_receiveMessage() error', e);
    }

    const shouldLogEvent = !message ||
      (message.eventName !== PLAYER_EVENTS.ON_PROGRESS && message.eventName !== PLAYER_EVENTS.MINI_PLAYER_MOVE);
    if (shouldLogEvent) this.logger.logDev('_receiveMessage() Event from player', message);

    if (message?.eventName === PLAYER_EVENTS.MINIMIZED_ACTION_OVERLAY) {
      const { withBackground } = message?.data || {};
      const closeMinimizeButton = document.querySelector('.livecommerce-close-minimized');
      if (closeMinimizeButton) closeMinimizeButton.style.background = withBackground ? 'none' : '';
    }

    if (message && message.eventName) {
      let eventName = message.eventName;
      if (eventName === PLAYER_EVENTS.LOAD) {
        this.setTrackingTags(this.trackingTags); // Make sure to update tracking service with added tags prior to player beign loaded
      }
      // Event emitted to the embedding page should not include the player event prefix.
      if (eventName.startsWith(PLAYER_EVENT_PREFIX)) {
        eventName = eventName.substr(PLAYER_EVENT_PREFIX.length);
      }

      this.playerEventsToForward.includes(message.eventName) // Just forward the event to embedding page?
        ? this._emitEvent(eventName, message.data, this._sendPlayerEventResponse.bind(this, message))
        : this._handlePlayerEvent(message);
    }
  }

  _receiveSurfFrameMessage(event) {
    let message = null;
    switch (typeof event.data) {
      case 'string':
        try {
          if (!event.data.includes(SURF_FRAME_EVENT_PREFIX)) {
            this.logger.log('_receiveSurfFrameMessage() skip non surf event message', event.data);
            return;
          }
          message = JSON.parse(event.data);
        } catch (e) {
          this.logger.log('_receiveSurfFrameMessage() error', e, 'event', event.data);
        }
        break;
      case 'object':
        message = event.data;
        break;
    }

    if (!this.surf.frame || this.surf.frame.contentWindow !== event.source) {
      // if source is not the iframe
      if (
        !message ||
        (
          message.eventName !== PLAYER_EVENTS.ON_PROGRESS &&
          message.eventName !== PLAYER_EVENTS.TRACKING_POINT &&
          message.eventName !== PLAYER_EVENTS.MINI_PLAYER_MOVE
        )
      ) {
        this.logger.log(
          '_receiveSurfFrameMessage() exit',
          event && event.data,
        );
      }
      return;
    }

    const shouldLogEvent = !message || message.eventName !== PLAYER_EVENTS.ON_PROGRESS;
    if (shouldLogEvent) this.logger.logDev('_receiveSurfFrameMessage() Event from surf frame', message);

    if (message && message.eventName) {
      this._handleSurfFrameEvent(message);
    }
  }

  _sendPlayerEventResponse(receivedEvent, responseData) {
    // prettier-ignore
    receivedEvent.responseId
      ? this._sendMessageToPlayer(PLAYER_EVENTS.RESPONSE, { id: receivedEvent.responseId, responseData })
      : this.logger.logProd(`Received response from embedding page for event '${
        receivedEvent.eventName}', but no response was expected. Ignoring it!`);
  }

  _getTimelineForTracking() {
    const { currentBroadcastId, currentTime, isLive, serverTimeAtCurrentPosition } = this.timeline;
    return {
      broadcastId: currentBroadcastId,
      isLive,
      relativeTime: currentTime,
      serverTime: serverTimeAtCurrentPosition,
    };
  }

  /**
   * Removes any potentially incriminating fields from
   * a configuration object
   * @param {object} config
   * @returns {object} clean config
   */
  _cleanPotentialPiiDataFromConfig(config) {
    const configClone = JSON.parse(JSON.stringify(config));

    delete configClone.chatName;

    return configClone;
  }

  _dispatchGlobalTrackingEvent(trackingPoint) {
    const event = new CustomEvent('bambuser-liveshop-tracking-point', { detail: trackingPoint });
    window.dispatchEvent(event);
    if (!this.config.withGA) return;
    bamlsTag('event', trackingPoint.event, bamlsTagData(trackingPoint.data));
  }

  _trackOnInteraction(interactionType, data = {}) {
    this._trackMetric('on-interaction', {
      ...data,
      interactionType,
    });
  }

  _trackMetric(eventName, data = {}) {
    if (!eventName) {
      this.logger.logProd("Can't track metric without providing an eventName");
      return;
    }

    const commonData = {
      insertId: uuidv4(),
      orgId: this.playerSettings.orgId,
      showId: this.config.eventId,
      userId: this._trackingContext?.userId,
      sessionId: this._trackingContext?.sessionId,
      source: 'embed',
      playerVersion: this.playerVersion || 0,
      referrerUrl: window.location.origin,
      timeline: this._getTimelineForTracking(),
      ...(this.trackingTags?.length > 0 && { customerTags: this.trackingTags }),
      ...data,
    };
    const trackingData = { eventType: eventName, ...commonData };
    const globalTrackingPointData = { event: eventName, data: { ...commonData } };

    if (this.config.eventId) {
      this._trackMetricEmit(trackingData);
    }
    this._dispatchGlobalTrackingEvent(globalTrackingPointData);
  }

  _trackMetricEmit(data) {
    if (!process.env.METRICS_ENDPOINT) return;
    const stringifiedData = JSON.stringify(data);
    const fetchAvailable = (window.Request && window.fetch);
    const fetchKeepaliveAvailable = fetchAvailable && 'keepalive' in window.Request.prototype;

    data.clientTimestamp = new Date().toISOString();

    // According to https://developer.mozilla.org/en-US/docs/Web/API/fetch#parameters the fetch API
    // with the 'keepalive' property will replace the Navigator.sendBeacon functionality. However,
    // this property does not have a perfect adoptation rate yet, so we will keep using Navigator.sendBeacon
    // in the cases where 'keepalive' is not supported.
    let sendBeaconSuccessful = false;
    if (!fetchAvailable && window.navigator && window.navigator.sendBeacon) {
      try {
        // Navigator.sendBeacon returns true if the user agent is able to successfully queue the data
        // for transfer but leaves no garueantee that the data will be successfully transferred. WebViews
        // which is presented in apps could very well have their Navigator.sendBeacon functionality wing clipped
        // by anti-tracking permission settings or the web browser running some adblock/uBlock origin type extensions.
        // Question is if we'll ever be able to detect this?
        sendBeaconSuccessful = window.navigator.sendBeacon(process.env.METRICS_ENDPOINT, stringifiedData);
      } catch (err) {
        // Let's use the last fallback below
      }
    }

    if (!sendBeaconSuccessful) {
      window.fetch(process.env.METRICS_ENDPOINT, {
        method: 'POST',
        body: stringifiedData,
        ...(fetchKeepaliveAvailable && { keepalive: true }),
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  _getUrlFromWindow() {
    const { origin, pathname, hash, search } = window.location;
    let urlParams = new URLSearchParams(search);
    urlParams.delete('autoplayLiveShopping'); // Don't start any auto-playing show please!
    urlParams = urlParams.toString();
    return `${origin}${pathname}${urlParams.length > 0 ? `?${urlParams}` : ''}${hash}`;
  }

  _handlePlayerEvent({ eventName, data }) {
    const { event: trackingEvent, data: trackingData } = data || {};

    // In these cases the "eventName" is the "responseId" we tagged the
    // request with in playerApiCall()
    if (typeof this._pendingRequestsToPlayer[eventName] === 'function') {
      this._pendingRequestsToPlayer[eventName](data);
      this._pendingRequestsToPlayer[eventName] = null;

      // We've now handled the response, so we can return early
      return;
    }

    switch (eventName) {
      case PLAYER_EVENTS.SET_CLOSE_ICON:
        BambuserLiveShopping.closeIcon = data.replace('<svg', '<svg class="theming-icon"');
        BambuserLiveShopping.closeIconV2 = data; // For version 2 UI we don't apply weird resizing like above
        break;
      case PLAYER_EVENTS.SET_RESTORE_ICON:
        BambuserLiveShopping.restoreIcon = data.replace('<svg', '<svg class="theming-icon"');
        break;
      case PLAYER_EVENTS.SET_VIDEO_STATE:
        Object.assign(this.video, data);
        if (this.__calculateMinimizedView) this.__calculateMinimizedView();
        break;
      case PLAYER_EVENTS.FOCUS_LEAVE:
        this.surf.frame.contentWindow.focus();
        break;
      case PLAYER_EVENTS.FOCUS_FIRST_OUTSIDE_IFRAME:
        Navigation.addUsingKeyboardClass();
        this.onMiniPlayerMouseEnterLeave({ type: 'mouseenter' });
        // setTimeout required otherwise we focus the restore/close button before it's re-rendered by the emitted event
        window.setTimeout(() => {
          const firstButton = this.miniPlayer.restoreButton || this.miniPlayer.closeButton;
          firstButton && firstButton.focus();
        }, 0);
        break;
      case PLAYER_EVENTS.FOCUS_LAST_OUTSIDE_IFRAME:
        Navigation.addUsingKeyboardClass();
        this.onMiniPlayerMouseEnterLeave({ type: 'mouseenter' });
        // setTimeout required otherwise we focus the close button before it's re-rendered by the emitted event
        window.setTimeout(() => {
          const lastButton = this.miniPlayer.closeButton || this.miniPlayer.restoreButton;
          lastButton && lastButton.focus();
        }, 0);
        break;
      case PLAYER_EVENTS.MINI_PLAYER_MOVE:
        if (typeof this.__handleMoveMinimizedPlayer === 'function') {
          this.__handleMoveMinimizedPlayer(data);
        }
        break;
      case PLAYER_EVENTS.MINI_PLAYER_DRAG_END:
        this._miniPlayerDragEnd();
        break;
      case PLAYER_EVENTS.ON_PROGRESS:
        this.timeline = data;
        break;
      case PLAYER_EVENTS.READY:
        Object.assign(this.playerSettings, data || {});

        if (!this._trackingContext && (this.playerSettings.userId || this.playerSettings.sessionId)) this._trackingContext = {};
        // Update the userId/sessionId in the tracking context if they are provided by the player
        if (this.playerSettings.userId) this._trackingContext.userId = this.playerSettings.userId;
        if (this.playerSettings.sessionId) this._trackingContext.sessionId = this.playerSettings.sessionId;

        this.showPlayer();
        this._trackMetric('on-configuration', {
          configuration: JSON.stringify(
            this._cleanPotentialPiiDataFromConfig(this.playerDelegate.config.rawConfig)
          ),
          subscribedEvents: this.playerDelegate.getSupportedEvents(),
          ...(this.earlyPlayerAPIMessages && { earlyPlayerAPIMessages: JSON.stringify(this.earlyPlayerAPIMessages) }),
        });
        // We initialize the surf behind with the landing page
        if (this.playerSettings.withMinimizeSupport && !this.surf.frame) {
          this.logger.log('Pre-initializing surf');
          this.surf.skipNextBrowserHistoryEntry = true;
          this.surfTo(this._getUrlFromWindow());
        }
        this.__windowUnloadHandler = () => this._trackOnInteraction('close', { actionOrigin: 'window' });
        window.addEventListener('unload', this.__windowUnloadHandler);
        break;
      case PLAYER_EVENTS.CLOSE:
        this._emitEvent(PLAYER_EVENTS_PUBLIC.CLOSE);
        this.exitPlayerFullscreen();
        if (this.__windowUnloadHandler) {
          window.removeEventListener('unload', this.__windowUnloadHandler);
          delete this.__windowUnloadHandler;
        }
        this.destroy();
        break;
      case PLAYER_EVENTS.MINIMIZE:
        let targetUrl = (data || {}).url || null;
        // When not having a surf behind iframe and no explicit url to load,
        // allow current page url to load inside the frame.
        if (!targetUrl && !this.surf.frame && this.config.surfBehindMode !== MANUAL) {
          targetUrl = this._getUrlFromWindow();
          this.surf.skipNextBrowserHistoryEntry = true;
        }

        if (targetUrl) {
          // Fallback to open in new window when device doesn't support surf behind iframe (and minimized player).
          if (!this.playerSettings.withMinimizeSupport) {
            return window.open(targetUrl, '_blank');
          }
          this.surfTo(targetUrl);
        }
        this.minimize();
        break;
      case PLAYER_EVENTS.MINI_PLAYER_RESTORE:
        if (typeof this.__restoreMinimized === 'function') {
          this.__restoreMinimized();
        }
        break;
      case PLAYER_EVENTS.FULLSCREEN_ENTER:
        this.enterPlayerFullscreen();
        break;
      case PLAYER_EVENTS.FULLSCREEN_EXIT:
        this.exitPlayerFullscreen();
        break;
      // Some in-app browsers disallow the player iframe to do this redirect
      // in which case we need to initiate the redirect from embed.js instead
      case PLAYER_EVENTS.OPEN_EXTERNAL_BROWSER:
        if (data.url) {
          window.location.href = data.url;
        }
        break;
      case PLAYER_EVENTS.TRACKING_POINT:
        if (!data) return;

        this._dispatchGlobalTrackingEvent(data);

        if (!this.shouldUpdateTrackingCookies) return;

        if (trackingData) {
          if (!this._trackingContext.showId && trackingData.showId) this._trackingContext.showId = trackingData.showId;
          if (!this._trackingContext.orgId && trackingData.orgId) this._trackingContext.orgId = trackingData.orgId;
          if (trackingEvent === 'on-play') this._trackingContext.showWasPlayed = true;
        }

        this._manageCookieRefreshInterval(trackingEvent);

        this._updateTrackingCookies();

        break;
      case PLAYER_EVENTS.TRACKING_SEND_EVENT:
        if (this.config.eventId) {
          this._trackMetricEmit({ source: 'embed', ...trackingData });
        }
        break;
      case PLAYER_EVENTS.RESET_BASE_PAGE_SCROLL:
        window.scrollTo(0, 0);
        break;
      case PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_NEW:
        this.playerDelegate.setupNewPlayerController(data.id);
        this._emitEvent(PLAYER_EVENTS_PUBLIC.EXTERNAL_PLAYER_CONTROLLER_NEW, data);
        break;
      case PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_CHANGE:
        const isNewController = data.id !== this.currentPlayerController();
        this.playerDelegate.changeCurrentPlayerController(data.id);
        if (isNewController) this._emitEvent(PLAYER_EVENTS_PUBLIC.EXTERNAL_PLAYER_CONTROLLER_CHANGE, data);
        break;
      case PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_PLAY:
        this._emitEvent(PLAYER_EVENTS_PUBLIC.EXTERNAL_PLAYER_CONTROLLER_PLAY, data);
        break;
      case PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_PAUSE:
        this._emitEvent(PLAYER_EVENTS_PUBLIC.EXTERNAL_PLAYER_CONTROLLER_PAUSE, data);
        break;
      case PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_SEEK:
        this._emitEvent(PLAYER_EVENTS_PUBLIC.EXTERNAL_PLAYER_CONTROLLER_SEEK, data);
        break;
      case PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_LOAD:
        this._emitEvent(PLAYER_EVENTS_PUBLIC.EXTERNAL_PLAYER_CONTROLLER_LOAD, data);
        break;
      case PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_HIDE:
        this._emitEvent(PLAYER_EVENTS_PUBLIC.EXTERNAL_PLAYER_CONTROLLER_HIDE, data);
        break;
      case PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_SHOW:
        this._emitEvent(PLAYER_EVENTS_PUBLIC.EXTERNAL_PLAYER_CONTROLLER_SHOW, data);
        break;
      case PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_REMOVE:
        this._emitEvent(PLAYER_EVENTS_PUBLIC.EXTERNAL_PLAYER_CONTROLLER_REMOVE, data);
        break;
      case PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_MUTE:
        this._emitEvent(PLAYER_EVENTS_PUBLIC.EXTERNAL_PLAYER_CONTROLLER_MUTE, data);
        break;
      default:
        this.logger.logDev('Received unhandled player event', eventName);
        break;
    }
  }

  loadShow(eventId, isNewShow = false) {
    // prettier-ignore
    this.logger.log(
      'loadShow(eventId)', eventId,
      '__restoreMinimized', !!this.__restoreMinimized,
      'eventId === config.eventId', eventId === this.config.eventId,
      'isNewShow', isNewShow,
    );

    if (!isNewShow && eventId === this.config.eventId && this.__restoreMinimized) {
      this.__restoreMinimized();
    } else {
      this.config.eventId = eventId;
      const { frame } = this.player;

      if (frame) {
        frame.classList.remove('ready');
        this.loader.classList.remove('hidden');
        if (this.__resetMinimized) {
          this.__resetMinimized();
        }
        // changing the iframe src once already changed will create a new entry in the browser history
        // to avoid this while loading a 2nd show, we should remove and recreate the player iframe
        const container = frame.parentNode;
        frame.remove();
        frame.setAttribute('src', this._getPlayerUrl());
        container.append(frame);
      } else {
        this.show();
      }
    }
  }

  // TODO: How to pause native video/audio elements?
  _pauseOtherPlaybacks() {
    // Stop all iframe embeds of 3rd-party video/audio services
    const iframes = document.querySelectorAll('iframe');
    // eslint-disable-next-line
    for (const iframe of iframes) {
      if (iframe.src && iframe.src.startsWith('https://www.youtube.com/embed')) {
        if (iframe.src.indexOf('enablejsapi=1') >= 0) {
          iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'pauseVideo' }), '*');
        } // else {} // TODO: if youtube iframe doesn't support JS API, reload and ensure that autoplay param is off??
      } // else {} // TODO: What other services should be stopped?
    }

    // Stop all iframe embeds of 3rd-party video/audio services in surf behind frame
    // It isn't possible to query and send post messages to iframes inside the surf behind frame for some reason,
    // so add a temporary script that executes in the surf frame context instead to archive the task at hand.
    if (this.surf.frame) {
      try {
        const surfIframeScript = this.surf.frame.contentWindow.document.createElement('script');
        surfIframeScript.innerHTML = `
        (function() {
          let iframes = document.querySelectorAll('iframe');
          for (let i = 0; i < iframes.length; i++) {
            let iframe = iframes[i];
            if (iframe.src && iframe.src.indexOf('https://www.youtube.com/embed') === 0) {
              if (iframe.src.indexOf('enablejsapi=1') >= 0) {
                iframe.contentWindow.postMessage(JSON.stringify({
                  event: 'command',
                  func: 'pauseVideo',
                }), '*');
              }
            }
          }
          document.currentScript.parentNode.removeChild(document.currentScript);
        })();`;
        this.surf.frame.contentWindow.document.body.appendChild(surfIframeScript);
      } catch (e) {
        /* Just swallow any cross-domain errors... */
      }
    }
  }

  _handleSurfFrameEvent({ eventName, data }) {
    this.logger.log('_handleSurfFrameEvent(eventName, data)', { eventName, data });

    switch (eventName) {
      case SURF_FRAME_EVENTS.REQUEST_OPEN_SHOW:
        const { config, requestId, fromNode } = data;

        if (!config || !requestId) {
          return;
        }

        const currentEventId = this.config.eventId;
        const isBambuserLiveShoppingExposed = !!window.__currentBambuserLiveShoppingOverlayPlayer;

        this.logger.log('Request open show', { currentEventId, isBambuserLiveShoppingExposed });

        // If autoplay & same show id than current playing show, we don't interrupt the current show for the same one
        // We skip autoplay to not reload the player while preloading the same page in the surf behind iframe
        // We can remove the check on the eventId once all our customers are not using autoplay code on a button click
        if (!fromNode && config.eventId === currentEventId && isBambuserLiveShoppingExposed) {
          this.logger.log('Skip autoplay');
        } else {
          const { eventId } = config;
          const isNewShow = eventId !== currentEventId;
          if (isNewShow) {
            // Mounting the API again will make new call to the mountPoint with the new eventId and make the
            // getProviderConfig method return a config specific to that new event
            this.mountPlayerAPI(eventId, true);
          }

          // Fetch the provider config for the show which is about to load
          // and merge it with any new configs which might be passed in the request
          // (notably deeplink is an interesting property which we want the new player config
          // to properly update).
          const providerConfig = this.getProviderConfig();
          // Allow to disable autoplay from the player configuration (only if autoplay is allowed for the customer)
          if (config.allowShareAutoplay) {
            if (providerConfig.allowShareAutoplay === false) config.allowShareAutoplay = false;
          } else if (providerConfig.allowShareAutoplay === true) {
            console.warn(
              WARNING.PLAYER_CONFIG_IGNORED_BY_ORGANIZATION_CONFIG('allowShareAutoplay', 'autoplayHandler'),
            );
          }
          this.config = Object.assign(providerConfig, config);

          this.loadShow(eventId, isNewShow);
        }

        const message = JSON.stringify({
          eventName: SURF_FRAME_EVENTS.ACCEPTED_REQUEST_OPEN_SHOW,
          data: { requestId },
        });
        this.surf.frame.contentWindow.postMessage(message, '*');
        break;
      case SURF_FRAME_EVENTS.REQUEST_SURF_BEHIND_TO:
        if (this.config.surfBehindMode === SRCDOC) {
          surfWithSrcdocFrame(this.getSurfFrame(), data);
          break;
        }
      default:
        this.logger.logDev('Received unhandled surf frame event', eventName);
        break;
    }
  }

  _sendMessageToPlayer(eventName, data = null) {
    if (!this.player.frame) {
      if (!this.earlyPlayerAPIMessages) {
        this.earlyPlayerAPIMessages = [];
      }
      if (!this.earlyPlayerAPIMessages.includes(eventName)) {
        this.earlyPlayerAPIMessages.push(eventName);
      }
      return;
    }

    this.logger.logDev('WILL EMIT TO PLAYER', eventName, data);

    const payload = { eventName };
    if (data) {
      payload.data = data;
    }

    let iframeRootUrl = this.config.playerUrl;
    if (iframeRootUrl.startsWith('//')) {
      iframeRootUrl = window.location.protocol + iframeRootUrl;
    }

    this.player.frame.contentWindow.postMessage(JSON.stringify(payload), iframeRootUrl);
  }

  _emitEvent(eventName, ...args) {
    // handle the million versions of args
    // TODO: Can we clean this up?
    const event = {};
    let [data = null, callback = null] = args;

    if (typeof data === 'function') {
      callback = data;
      data = null;
    }

    if (data !== null) {
      event.data = data;
    }

    if (typeof callback === 'function') {
      event.callback = callback;
    }

    // actually send the event
    this.playerDelegate.pushEvent(eventName, event);

    if (typeof window.BambuserLiveShoppingEventHandler === 'function') {
      // legacy
      window.BambuserLiveShoppingEventHandler({ type: eventName, player: this, ...event });
    }
  }

  _updateTrackingCookies() {
    if (!this.shouldUpdateTrackingCookies) return;

    const { cookie: options = {}, isVod } = this.config;
    const { domain, activityCookieTTLDays } = options;
    const cookieConf = {
      expires: 365,
      ...(domain && { domain }),
    };
    const { orgId, userId, showId, sessionId, showWasPlayed } = this._trackingContext;

    if (orgId) {
      writeCookie(TRACKING_COOKIES.CUSTOMER_ID, orgId, cookieConf);
    }

    if (userId) {
      writeCookie(TRACKING_COOKIES.USER_ID, userId, cookieConf);
    }

    if (sessionId) {
      const inThirtyMinutes = new Date(new Date().getTime() + 30 * 60 * 1000);
      writeCookie(TRACKING_COOKIES.SESSION_ID, sessionId, { ...cookieConf, expires: inThirtyMinutes });
    }

    const expiresInDays = activityCookieTTLDays > 0 ? activityCookieTTLDays : 30;
    if (showId && !isVod) {
      const activityCookieConf = { ...cookieConf, expires: expiresInDays };
      writeCookie(TRACKING_COOKIES.SHOW_ID, showId, activityCookieConf);
      if (showWasPlayed) {
        writeCookie(TRACKING_COOKIES.LAST_INTERACTION_TIMESTAMP, new Date().getTime(), activityCookieConf);
      }
    }
    if (isVod) {
      let localizedConversionTrackingExpiryCookie = {};
      const expiryTimestamp = Date.now() + (86400000 * expiresInDays);
      try {
        localizedConversionTrackingExpiryCookie = JSON.parse(
          readCookie(TRACKING_COOKIES.LOCALIZED_CONVERSION_TRACKING_EXPIRY) || {}
        );
        if (localizedConversionTrackingExpiryCookie.constructor !== Object) {
          localizedConversionTrackingExpiryCookie = {};
        }
      } catch (e) {
        // cookie did not contain parseable JSON so we can ignore it and start over
      }

      const locationKey = process.env.PROJECT_ID.endsWith('eu') ? 'eu' : 'us';
      localizedConversionTrackingExpiryCookie[locationKey] = expiryTimestamp;

      const latestExpiryTimestamp = Math.max(...Object.values(localizedConversionTrackingExpiryCookie));

      writeCookie(
        TRACKING_COOKIES.LOCALIZED_CONVERSION_TRACKING_EXPIRY,
        JSON.stringify(localizedConversionTrackingExpiryCookie),
        {
          ...cookieConf,
          expires: new Date(latestExpiryTimestamp),
        }
      );
    }
  }

  /**
   * Starts, resets or clears the 60 second interval after which cookies will be refreshed.
   * If trackingEvent is "on-stop" the interval is only cleared, not restarted.
   * When started it runs until manually stopped or restarted again.
   * @param {METRIC_TYPE} trackingEvent
   */
  _manageCookieRefreshInterval(trackingEvent) {
    if (!this.shouldUpdateTrackingCookies) return;

    this._clearCookieRefreshInterval();

    if (!this._trackingContext.showWasPlayed) return; // We do not want to automatically refresh cookies unless show was played
    if (trackingEvent === 'on-stop') return; // We do not want to start a new interval after show has finished

    this._cookieRefreshInterval = window.setInterval(() => {
      this._updateTrackingCookies();
    }, 60 * 1000);
  }

  _clearCookieRefreshInterval() {
    if (!this._cookieRefreshInterval) return;

    window.clearInterval(this._cookieRefreshInterval);
    delete this._cookieRefreshInterval;
  }

  _viewportChangeHandler(e) {
    const height = e.target.viewport?.height ?? e.target.height;

    this._sendMessageToPlayer(PLAYER_EVENTS.CURRENT_BASEPAGE_VIEWPORT, { height });
  }

  _pushPageScrollTopHandler() {
    // Throttle scroll event - clear existing interval or send current scroll pos directly if first event
    this.__pushPageScrollTopTimeout
      ? window.clearTimeout(this.__pushPageScrollTopTimeout)
      : this._sendMessageToPlayer(PLAYER_EVENTS.CURRENT_BASEPAGE_SCROLLTOP, { scrollTop: window.scrollY });

    // Throttle scroll event - Wait some time until sending current scroll pos as scroll events can fire crazy often.
    this.__pushPageScrollTopTimeout = window.setTimeout(() => {
      delete this.__pushPageScrollTopTimeout;
      this._sendMessageToPlayer(PLAYER_EVENTS.CURRENT_BASEPAGE_SCROLLTOP, { scrollTop: window.scrollY });
    }, 150);
  }

  _disableParentScroll() {
    document.documentElement.style.setProperty('overflow', 'hidden', 'important'); // documentElement = html
    document.body.style.setProperty('overflow', 'hidden', 'important');
    document.body.style.overscrollBehavior = 'none'; // Disables pull-to-refresh and overscroll glow effect
  }

  _restoreParentScroll() {
    document.documentElement.style.setProperty('overflow', ''); // documentElement = html
    document.body.style.setProperty('overflow', '');
    document.body.style.overscrollBehavior = '';
  }

  /*
   * Get the current player controller
   */
  currentPlayerController() {
    return this.playerDelegate.currentPlayerController();
  }

  /*
   * Get all available player controllers
   */
  playerControllers() {
    return this.playerDelegate.playerControllers();
  }

  /*
   * Function to provide the video players event data to the LVS web app player controller
   */
  externalPlayerControllerEvent(data) {
    this._sendMessageToPlayer(PLAYER_EVENTS.EXTERNAL_PLAYER_CONTROLLER_EVENTS, data);
  }

  isAutoplayAllowed() {
    return !elcBrands.includes(window.btoa(this.customization));
  }
}

BambuserLiveShopping.restoreIcon = defaultRestoreIcon;
BambuserLiveShopping.closeIcon = defaultCloseIcon;
BambuserLiveShopping.closeIconV2 = defaultCloseIconV2;

export default BambuserLiveShopping;
