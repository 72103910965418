import { BAM_PLAYER_FIT } from './constants';

export const getStyleSheetForBamPlayer = ({
  aspectRatio,
  fontSize,
  showOnlyThumbnails,
  poster,
  playerFit,
  showPosterWhenPaused,
  playerActionCardBorderRadius,
}) => {
  let effectiveHeight;
  let effectiveWidth;

  switch (playerFit) {
    case BAM_PLAYER_FIT.FILL_PARENT:
      effectiveWidth = `100%`;
      effectiveHeight = `100%`;
      break;
    case BAM_PLAYER_FIT.EXACT_SIZE:
      effectiveWidth = `var(--bam-player-width, var(--internal-width, 250px))`;
      effectiveHeight = `var(--bam-player-height, var(--internal-height, 444px))`;
      break;
    case BAM_PLAYER_FIT.SAME_WIDTH:
      // same width, different height
      effectiveWidth = `var(--bam-player-width, var(--internal-width, 250px))`;
      effectiveHeight = `calc(${effectiveWidth} / ${aspectRatio})`;
      break;
    case BAM_PLAYER_FIT.SAME_HEIGHT:
    default:
      // same height, different width
      // default option
      effectiveHeight = `var(--bam-player-height, var(--internal-height, 444px))`;
      effectiveWidth = `calc(${effectiveHeight} * ${aspectRatio})`;
      break;
  }

  /* eslint-disable */
  return `
    :host {
        display: ${aspectRatio !== undefined ? 'block' : 'none'};
        max-width: ${effectiveWidth};
        position: relative;
        aspect-ratio: ${aspectRatio};
        font-size: ${fontSize}px;
        overflow: hidden;
        overscroll-behavior: none;
        border-radius: var(--bam-player-corner-radius, var(--internal-corner-radius, 0px));
        width: ${effectiveWidth};

        --effective-height: ${effectiveHeight};
        height: var(--effective-height);

        --title-10-font-size: var(--bam-player-title-10-font-size, 22px);
        --title-10-line-height: var(--bam-player-title-10-line-height, 1);

        --title-20-font-size: var(--bam-player-title-20-font-size, 16px);
        --title-20-line-height: var(--bam-player-title-20-line-height, 1);

        --subtitle-20-font-size: var(--bam-player-subtitle-20-font-size, 14px);
        --subtitle-20-line-height: var(--bam-player-subtitle-20-line-height, 1);
    }
    * {
        user-select: none;
    }
    iframe {
        width: 100%;
        height: 100%;
        transition: opacity 0.2s;
        opacity: 1;
    }
    .wrapper {
        position: absolute;
        inset: 0px;
        z-index: 1;
    }
    .placeholder {
        height: 100%;
        width: 100%;
        ${
          !poster
            ? `background-color: var(--bam-player-placeholder-color, var(--internal-placeholder-color, #EEE));`
            : `
            background-image: url(${poster});
            background-size: cover;
            background-position: center;
            `
        }
    }
    .overlay {
        position: absolute;
        inset: 0px;
        display: flex;
        align-items: end;
        flex-direction: row;
        z-index: 2;
        pointer-events: none;
    }
    .overlay .products {
        position: absolute;
        bottom: 0;
        display: flex;
        padding: min(5%, 12px);
        box-sizing: border-box;
        width: 100%;
        background-image: linear-gradient(rgba(51,51,51,0),rgba(0,0,0,.4));
    }
    .overlay .products .product {
        max-width: max(33%, 410px);
        display: flex;
        flex-direction: row;
        gap: min(0.8em, 8px);

        border-radius: var(--bam-player-action-card-corner-radius, ${playerActionCardBorderRadius ? `${playerActionCardBorderRadius}px` : '4px'});

        padding: var(--bam-player-action-card-padding, min(0.6em, 6px));
        background-color: white;
        align-items: center;
        overflow: hidden;
    }
    .overlay .products .product .product-info {
        overflow: hidden;
        padding-right: min(0.5em, 4px);
        ${showOnlyThumbnails ? 'display: none;' : ''}
    }
    .overlay .products .product .product-info .product-title {
        font-family: var(--internal-font-family), Helvetica, Arial, Verdana, Tahoma, sans-serif;
        font-size: var(--title-20-font-size);
        line-height: var(--title-20-line-height);
        font-weight: var(--bam-player-overlay-font-weight, var(--internal-overlay-font-weight, bold));

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        padding-bottom: 6px;
    }
    .overlay .products .product .product-info .product-subtitle {
        font-family: var(--internal-font-family), Helvetica, Arial, Verdana, Tahoma, sans-serif;
        font-size: var(--subtitle-20-font-size);
        line-height: var(--subtitle-20-line-height);
        font-weight: light;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
    }
    .overlay .products .product .product-info .product-price {
        font-family: var(--internal-font-family), Helvetica, Arial, Verdana, Tahoma, sans-serif;
        font-size: var(--subtitle-20-font-size);
        line-height: var(--subtitle-20-line-height);
        font-weight: var(--bam-player-overlay-font-weight, var(--internal-overlay-font-weight, bold));

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        padding-top: 6px;
    }
    .overlay .products .product .product-thumbnail-wrapper {
        width: var(--bam-player-thumbnail-size, 68px);
        height: var(--bam-player-thumbnail-size, 68px);
        border-radius: var(--bam-player-action-card-corner-radius, 4px);
    }
    .overlay .products .product .product-thumbnail-wrapper img {
        width: var(--bam-player-thumbnail-size, 68px);
        height: var(--bam-player-thumbnail-size, 68px);
        object-fit: contain;
        border-radius: var(--bam-player-action-card-corner-radius, 4px);;
    }
    .overlay .products .product h2, .overlay .products .product h3 {
        color: #FFF;
        font-size: 16px;
        margin: 0;
    }
    .overlay .basic-info {
        color: #FFF;
        font-family: var(--internal-font-family), Helvetica, Arial, Verdana, Tahoma, sans-serif;
        background-image: linear-gradient(rgba(51,51,51,0),rgba(0,0,0,.4));
        width: 100%;
        box-sizing: border-box;
        padding: 15% 5% 5% 5%;
    }
    .overlay .basic-info h1 {
        font-size: var(--title-10-font-size);
        line-height: var(--title-10-line-height);
        font-weight: var(--bam-player-overlay-font-weight, var(--internal-overlay-font-weight, bold));
        margin: 0;
    }
    .overlay .basic-info:not(.wrap-text) h1 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .wrapper:not(.player-ui-loaded) {
        cursor: pointer;
    }
    .wrapper:not(.player-ui-loaded) iframe {
        pointer-events: none;
    }
    .wrapper.player-ui-loaded .overlay, .is-expanded .overlay {
        display: none;
    }
    ${
      (poster && showPosterWhenPaused) ?
      `
        .wrapper:not(.is-playing) iframe {
            opacity: 0;
        }
        `: ''
    }
    ${
      (poster && !showPosterWhenPaused) ?
      `
        .wrapper:not(.played-at-least-once):not(.is-playing) iframe {
            opacity: 0;
        }
        `: ''
    }
    `;
};
/* eslint-enable */
